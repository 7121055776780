import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Stack,
  Heading,
  FormErrorMessage,
  HStack,
} from '@chakra-ui/react';
import { Field, ErrorMessage } from 'formik';

const AddressForm = ({ formTitle, namePrefix }: any) => {
  return (
    <Box p={4} borderWidth={1} borderRadius="lg">
      <Heading as="h4" size="lg" mb={4}>
        {formTitle}
      </Heading>
      <Stack spacing={4}>
        <FormControl id={`${namePrefix}Name`} isRequired>
          <FormLabel>Name</FormLabel>
          <Field
            name={`${namePrefix}Name`}
            as={Input}
            placeholder="Enter name"
            type="text"
          />
          <ErrorMessage
            name={`${namePrefix}Name`}
            component={FormErrorMessage}
          />
        </FormControl>
        <HStack>
          {/* email */}
          <FormControl id={`${namePrefix}Email`} isRequired>
            <FormLabel>Email</FormLabel>
            <Field
              name={`${namePrefix}Email`}
              as={Input}
              placeholder="Enter email"
              type="email"
            />
            <ErrorMessage
              name={`${namePrefix}ContactNumber`}
              component={FormErrorMessage}
            />
          </FormControl>
          {/* contact */}
          <FormControl id={`${namePrefix}ContactNumber`} isRequired>
            <FormLabel>Contact Number</FormLabel>
            <Field
              name={`${namePrefix}ContactNumber`}
              as={Input}
              placeholder="Enter contact number"
              type="number"
            />
            <ErrorMessage
              name={`${namePrefix}ContactNumber`}
              component={FormErrorMessage}
            />
          </FormControl>
        </HStack>

        <HStack>
          {/* address-1 */}
          <FormControl id={`${namePrefix}AddressLine1`} isRequired>
            <FormLabel>Address Line 1</FormLabel>
            <Field
              name={`${namePrefix}AddressLine1`}
              as={Input}
              placeholder="Enter address line 1"
              type="string"
            />
            <ErrorMessage
              name={`${namePrefix}AddressLine1`}
              component={FormErrorMessage}
            />
          </FormControl>
          {/* address-2 */}
          <FormControl id={`${namePrefix}AddressLine2`}>
            <FormLabel>Address Line 2</FormLabel>
            <Field
              name={`${namePrefix}AddressLine2`}
              as={Input}
              placeholder="Enter address line 2"
              type="string"
            />
            <ErrorMessage
              name={`${namePrefix}AddressLine2`}
              component={FormErrorMessage}
            />
          </FormControl>
        </HStack>
        <HStack>
          {/* city */}
          <FormControl id={`${namePrefix}City`} isRequired>
            <FormLabel>City</FormLabel>
            <Field
              name={`${namePrefix}City`}
              as={Input}
              placeholder="Enter city"
              type="string"
            />
            <ErrorMessage
              name={`${namePrefix}City`}
              component={FormErrorMessage}
            />
          </FormControl>
          {/* state */}
          <FormControl id={`${namePrefix}State`} isRequired>
            <FormLabel>State/Province</FormLabel>
            <Field
              name={`${namePrefix}State`}
              as={Input}
              placeholder="Enter state or province"
              type="string"
            />
            <ErrorMessage
              name={`${namePrefix}State`}
              component={FormErrorMessage}
            />
          </FormControl>
        </HStack>
        <HStack>
          {/* zip */}
          <FormControl id={`${namePrefix}PostalCode`} isRequired>
            <FormLabel>Postal/ZIP Code</FormLabel>
            <Field
              name={`${namePrefix}PostalCode`}
              as={Input}
              placeholder="Enter postal or ZIP code"
              type="number"
            />
            <ErrorMessage
              name={`${namePrefix}PostalCode`}
              component={FormErrorMessage}
            />
          </FormControl>
          {/* country */}
          <FormControl id={`${namePrefix}Country`} isRequired>
            <FormLabel>Country</FormLabel>
            <Field
              name={`${namePrefix}Country`}
              as={Input}
              placeholder="Enter country"
              type="string"
            />
            <ErrorMessage
              name={`${namePrefix}Country`}
              component={FormErrorMessage}
            />
          </FormControl>
        </HStack>
      </Stack>
    </Box>
  );
};

export default AddressForm;

'use client';
import React from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Text,
  VStack,
  Image,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import CustomInput from '../../components/CustomInput';

interface CustomInputProps {
  email?: string;
  type?: string;
}

const OtpPage: React.FC<CustomInputProps> = () => {
  return (
    <Flex direction={{ base: 'column', md: 'row' }} h={'100vh'}>
      {/* login left-box */}
      <Box
        backgroundImage={'/login-img.png'}
        backgroundSize={'cover'}
        backgroundPosition={'center center'}
        width={{ base: '100%', md: '50%' }}
        h={'100vh'}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minH={'500px'}
      >
        <VStack>
          <Link to={'/'}>
            <Text
              textAlign={'center'}
              fontWeight={'bold'}
              color={'#fff'}
              fontSize={{ base: '3xl', md: '5xl' }}
            >
              Skyshare Cargo
            </Text>
            <Text
              textAlign={'center'}
              fontWeight={'md'}
              color={'#fff'}
              fontSize={{ base: 'xl', md: '2xl' }}
            >
              Aircraft Cargo Right Sizing For Low Cost & reduced Emission
            </Text>
          </Link>
        </VStack>
      </Box>
      {/* login right-box */}
      <Formik
        initialValues={{ email: '' }}
        onSubmit={(values, { resetForm }) => {
          console.log(values);
          resetForm();
        }}
      >
        <Box
          width={{ base: '100%', md: '50%' }}
          h={'100vh'}
          overflowY={{ base: 'visible', md: 'auto' }}
        >
          <VStack>
            <Box ml="auto" mt={'15%'}>
              <Image src="/flogo.svg" width={{ xs: 200, sm: 300 }} alt="logo" />
            </Box>
            <Box>
              <Text
                textAlign={'center'}
                fontWeight={'bold'}
                color={'#3C505B'}
                fontSize={{ base: '3xl', md: '5xl' }}
              >
                Welcome
              </Text>
              <Text
                textAlign={'center'}
                fontWeight={'md'}
                color={'gray.500'}
                fontSize={{ base: 'md', md: 'xl' }}
              >
                Set your Password
              </Text>
            </Box>
          </VStack>
          <Center>
            <Form>
              <VStack spacing={4} my={'24px'}>
                {/* email */}
                <CustomInput label="Email" name="email" type="email" />

                {/* Login_btn */}
                <Button
                  color={'#fff'}
                  width={{ base: 'xs', md: 'md' }}
                  backgroundColor={'#3C505B'}
                  borderRadius="50px"
                  _hover={{ backgroundColor: 'black' }}
                  type={'submit'}
                >
                  Reset Password
                </Button>
              </VStack>
            </Form>
          </Center>
        </Box>
      </Formik>
    </Flex>
  );
};

export default OtpPage;

import { Box, Flex, Text, Image, HStack, VStack } from '@chakra-ui/react';
import React from 'react';
import Layout from '../../layout/Layout';
import assets from '../../assets';
const PaymentPage = () => {
  return (
    <Layout>
      <Box py={'2%'}>
        <Flex direction={{ sm: 'column', xl: 'row' }}>
          {/* Left-side */}
          <Box
            padding={'2%'}
            width={{ sm: '100%', xl: '55%' }}
            textAlign={'left'}
          >
            <Box>
              <Text
                fontWeight={'bold'}
                color={'#011C2A'}
                fontSize={{ base: '2xl', md: '4xl', lg: '4xl' }}
                marginBottom={'10px'}
              >
                Charges and Payments
              </Text>
              <Text fontSize={{ sm: '14px', lg: '16px' }}>
                At Skyshare Cargo we offer many easy payment options. You may
                use a maximum of two forms of payment per shipment. However, you
                may not combine a billable account with any form of payment.
                Cash and check payment options are no longer available at the
                facilities. We will continue to accept billable accounts, debit,
                and credit card payments. Visit the news page for more details.
              </Text>
            </Box>
            <Box mt={'20px'}>
              <Text
                fontSize={{ base: '2xl', md: '3xl' }}
                fontWeight={'md'}
                marginBottom={'10px'}
              >
                Forms of Payment Accepted at the Cargo Facility
              </Text>
              <Image marginBottom={'10px'} src={assets.payment} />
              <Text fontSize={{ sm: '14px', lg: '16px' }}>
                Explore Our Accepted Payment Methods. Experience peace of mind
                with our online payment options, including credit cards, debit
                cards, and online banking. Fast, secure, and easily monitored,
                ensuring your transactions are as smooth as your cargo is
                journey.
              </Text>
            </Box>
            <Box mt={'20px'}>
              <Text
                fontSize={{ base: '2xl', md: '3xl' }}
                fontWeight={'md'}
                marginBottom={'10px'}
              >
                Credit Cards
              </Text>
              <HStack mt={'10x'} justifyContent={'space-around'}>
                <Image
                  width={{ sm: '60px', md: 'auto' }}
                  marginBottom={'10px'}
                  src={assets.master}
                />
                <Image
                  width={{ sm: '60px', md: 'auto' }}
                  marginBottom={'10px'}
                  src={assets.visa}
                />
                <Image
                  width={{ sm: '100px', md: 'auto' }}
                  marginBottom={'10px'}
                  src={assets.discover}
                />
                <Image
                  width={{ sm: '100px', md: 'auto' }}
                  marginBottom={'10px'}
                  src={assets.express}
                />
              </HStack>
            </Box>
          </Box>
          {/* Right-side */}
          <Box
            background={'#EBEDEF'}
            padding={'2%'}
            width={{ sm: '100%', xl: '45%' }}
          >
            <VStack alignItems={'flex-start'} spacing={'40px'}>
              <Text
                fontWeight={'bold'}
                color={'#011C2A'}
                fontSize={{ base: '2xl', md: '4xl', lg: '4xl' }}
                marginBottom={'10px'}
              >
                Additional Payment Options
              </Text>
              <Box>
                <Text
                  fontSize={{ base: '2xl', md: '3xl' }}
                  fontWeight={'md'}
                  marginBottom={'10px'}
                >
                  Billable Accounts
                </Text>
                <Text fontSize={{ sm: '14px', md: '16px' }}>
                  In order to add billing to your existing account, please
                  complete our Update Account Form. To maintain billable account
                  privileges, you must pay in accordance with the payment terms
                  set forth on the invoice. Skyshare Cargo retains the right to
                  refuse or remove billable account privileges at any time. In
                  order to establish a billable account, please visit
                  our application to complete the online application.
                </Text>
              </Box>
              <Box>
                <Text
                  fontSize={{ base: '2xl', md: '3xl' }}
                  fontWeight={'md'}
                  marginBottom={'10px'}
                >
                  Available Forms of Payment For Invoicing
                </Text>
                <Text fontSize={{ sm: '14px', md: '16px' }}>
                  To pay your invoice we only accept wire or ACH.
                </Text>
              </Box>
              <Box>
                <Text
                  fontSize={{ base: '2xl', md: '3xl' }}
                  fontWeight={'md'}
                  marginBottom={'10px'}
                >
                  Declared Value Coverage
                </Text>
                <Text fontSize={{ sm: '14px', md: '16px' }}>
                  At Skyshare Cargo, we consider each item you ship valuable,
                  and we treat it that way. At times, you may need to ship items
                  that require additional coverage. And we can assist you in
                  purchasing declared value coverage for your precious cargo.
                  For more information, refer to our declared value page.
                </Text>
              </Box>
              <Box>
                <Text
                  fontSize={{ base: '2xl', md: '3xl' }}
                  fontWeight={'md'}
                  marginBottom={'10px'}
                >
                  International Payments
                </Text>
                <Text fontSize={{ sm: '14px', md: '16px' }}>
                  All shipments originating in an international point of origin
                  must be prepaid utilizing a Skyshare Cargo Master Account. We
                  currently do not accept cash, check, credit card, or collect
                  as a form of payment.
                </Text>
              </Box>
            </VStack>
          </Box>
          
        </Flex>
      </Box>
    </Layout>
  );
};

export default PaymentPage;

import React, { useState } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Text,
  VStack,
  Image,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { BsGoogle } from 'react-icons/bs';
import { FiGithub } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import CustomInput from '../../components/CustomInput';
import { useToast } from '@chakra-ui/react';
import { useLoginUserMutation } from '../../store/slices/loginApiSlice';
import { login } from '../../util/AuthUtils';
interface CustomInputProps {
  label?: string;
  name?: string;
  type?: string;
  showPassword?: boolean;
  togglePassword?: () => void;
}

const LoginPage: React.FC<CustomInputProps> = () => {
  const [loginUser] = useLoginUserMutation();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const navigate = useNavigate();
  const toast = useToast();

  return (
    <Flex direction={{ base: 'column', md: 'row' }} h={'100vh'}>
      {/* login left-box */}
      <Box
        backgroundImage={'/login-img.png'}
        backgroundSize={'cover'}
        backgroundPosition={'center center'}
        width={{ base: '100%', md: '50%' }}
        h={'100vh'}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minH={'500px'}
      >
        <VStack>
          <Link to={'/'}>
            <Text
              textAlign={'center'}
              fontWeight={'bold'}
              color={'#fff'}
              fontSize={{ base: '3xl', md: '5xl' }}
            >
              Skyshare Cargo
            </Text>
            <Text
              textAlign={'center'}
              fontWeight={'md'}
              color={'#fff'}
              fontSize={{ base: 'xl', md: '2xl' }}
            >
              Aircraft Cargo Right Sizing For Low Cost & reduced Emission
            </Text>
          </Link>
        </VStack>
      </Box>
      {/* login right-box */}
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={async (values, { resetForm }) => {
          try {
            const response = await loginUser(values).unwrap();
            login(response.accessToken);
            resetForm();
            navigate('/');

            toast({
              title: 'User logged in successfully',
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
          } catch (error: any) {
            console.error('Login failed:', error);
            const errorMessage =
              error?.response?.data?.message ||
              error.message ||
              'Something went wrong. Please try again.';

            toast({
              title: 'Error',
              description: errorMessage, 
              status: 'error',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
          }
        }}
      >
        <Box
          width={{ base: '100%', md: '50%' }}
          h={'100vh'}
          overflowY={{ base: 'visible', md: 'auto' }}
        >
          <VStack>
            <Box ml="auto" mt={'4%'}>
              <Image src="/flogo.svg" width={{ xs: 200, sm: 300 }} alt="logo" />
            </Box>
            <Box>
              <Text
                textAlign={'center'}
                fontWeight={'bold'}
                color={'#3C505B'}
                fontSize={{ base: '3xl', md: '5xl' }}
              >
                Welcome
              </Text>
              <Text
                textAlign={'center'}
                fontWeight={'md'}
                color={'gray.500'}
                fontSize={{ base: 'md', md: 'xl' }}
              >
                Login with Email
              </Text>
            </Box>
          </VStack>
          <Center>
            <Form>
              <VStack spacing={4} my={'24px'}>
                {/* email */}
                <CustomInput label="Email" name="email" type="email" />
                {/* password */}
                <CustomInput
                  label="Password"
                  name="password"
                  type={show ? 'text' : 'password'}
                  // showPassword={showPassword}
                  togglePassword={handleClick}
                />
                {/* Forgotpassword */}

                <Button
                  color={'#3C505B'}
                  px="0px"
                  ml="auto"
                  variant={'ghost'}
                  _hover={{ backgroundColor: 'white' }}
                >
                  <Link to={'/otp'}>Forgot password?</Link>
                </Button>
                {/* Login_btn */}
                <Button
                  color={'#fff'}
                  width={{ base: 'xs', md: 'md' }}
                  backgroundColor={'#3C505B'}
                  borderRadius="50px"
                  _hover={{ backgroundColor: 'black' }}
                  type={'submit'}
                >
                  Login
                </Button>
                <Text color="gray.400">or</Text>
                {/* social sign-in sso */}
                <HStack>
                  <Button
                    width={{ sm: '100px', md: '200px' }}
                    variant={'outline'}
                    borderColor={'#3C505B'}
                    borderRadius="50px"
                    leftIcon={<BsGoogle />}
                  >
                    Google
                  </Button>
                  <Button
                    width={{ sm: '100px', md: '200px' }}
                    variant={'outline'}
                    borderColor={'#3C505B'}
                    borderRadius="50px"
                    leftIcon={<FiGithub />}
                  >
                    GitLab
                  </Button>
                </HStack>
                <Text color="#3C505B">Have no account yet?</Text>
                {/* registartion_btn */}
                <Link to={'/signup'}>
                  <Button
                    color="#3C505B"
                    width={{ base: 'xs', md: 'md' }}
                    variant={'outline'}
                    borderColor={'#3C505B'}
                    borderRadius="50px"
                  >
                    Registration
                  </Button>
                </Link>
              </VStack>
            </Form>
          </Center>
        </Box>
      </Formik>
    </Flex>
  );
};

export default LoginPage;

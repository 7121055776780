import React from 'react';
import { Navigate } from 'react-router-dom';
import Loading from '../components/LoaderComponent';
import { isAuthenticated } from '../util/AuthUtils';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const [loading, setLoading] = React.useState(true);
  const [auth, setAuth] = React.useState(false);

  React.useEffect(() => {
    const checkAuth = async () => {
      const authenticated = isAuthenticated();
      setAuth(authenticated);
      setLoading(false);
    };
    checkAuth();
  }, []);

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (!auth) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;

'use client';
import React from 'react';
import Layout from '../../layout/Layout';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';

const Productpage = () => {
  return (
    <Layout>
      <Box>
        <Flex direction={{ base: 'column', xl: 'row' }}>
          <Box
            width={{ base: '100%', xl: '50%' }}
            h={'100vh'}
            backgroundImage="url('/product.png')"
            backgroundSize={'cover'}
            backgroundPosition={'center'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDir={'column'}
            px={'60px'}
          >
            {/* Text */}
            <Box
              color="#fff"
              display={'flex'}
              alignItems={'center'}
              flexDir={'column'}
            >
              <Text
                fontWeight="bold"
                fontSize={{ base: 'xl', md: '2xl', lg: '4xl' }}
                textAlign={'center'}
              >
                What We Can Provide To You
              </Text>
              <Text
                textAlign={'center'}
                fontSize={{ base: '14px', md: '16px', lg: '18px' }}
                mt={'10px'}
              >
                Our selection takes you on a voyage through a world of goods.
                Whether it is everyday essentials or specialized treasures, our
                product lineup awaits to fly them to you. Explore now and let
                your shipping experience to book a cargo.
              </Text>
            </Box>
          </Box>

          <Box
            width={{ base: '100%', xl: '50%' }}
            px={{ base: '4%', xl: '0' }}
            py={{ base: '4%', xl: 0 }}
          >
            {/* first-row */}
            <Box
              display={'flex'}
              flexDir={{ base: 'column', xl: 'row' }}
              gap={'10px'}
              mt={{ base: '-25%', md: '-15%', xl: '0%' }}
              ml={{ base: '0', xl: '-10' }}
              borderRadius={'md'}
              mb="2%"
              className="boxPadding "
            >
              {/* box-1 */}
              <Box
                width={'fit-content'}
                height={'fit-content'}
                boxShadow={'md'}
                borderRadius={'md'}
                border={'1px solid #D9D9D9'}
                px={'10px'}
                py={'30px'}
                bg={'#fff'}
              >
                <Box>
                  <HStack>
                    <Box height={'10px'} width={'100px'} background={'black'} />
                    <Text
                      fontWeight={'bold'}
                      my={'5px'}
                      fontSize={{ base: '18px', md: '18px', lg: '21px' }}
                    >
                      01
                    </Text>
                  </HStack>
                  <Text
                    fontWeight={'bold'}
                    my={'5px'}
                    fontSize={{ base: '18px', md: '18px', lg: '21px' }}
                  >
                    Medical Product
                  </Text>
                  <Text fontSize={'14px'} my={'22px'}>
                    Explore our comprehensive collection of medical supplies,
                    curated to meet the demands of healthcare professionals and
                    individuals alike. From essential medications to
                    state-of-the-art equipment, we ensure the timely delivery of
                    critical items to support your well-being.
                  </Text>
                </Box>
              </Box>
              {/* box-2 */}
              <Box
                width={'fit-content'}
                height={'fit-content'}
                boxShadow={'md'}
                borderRadius={'md'}
                border={'1px solid #D9D9D9'}
                px={'10px'}
                py={'40px'}
                bg={'#fff'}
              >
                <Box>
                  <HStack>
                    <Box height={'10px'} width={'100px'} background={'black'} />
                    <Text
                      fontWeight={'bold'}
                      my={'5px'}
                      fontSize={{ base: '18px', md: '18px', lg: '21px' }}
                    >
                      02
                    </Text>
                  </HStack>
                  <Text
                    fontWeight={'bold'}
                    my={'5px'}
                    fontSize={{ base: '18px', md: '18px', lg: '21px' }}
                  >
                    Partible Items
                  </Text>
                  <Text fontSize={'14px'}>
                    Explore our range of Partible Items, meticulously chosen to
                    suit your versatile needs. From fresh produce to perishable
                    goods, our selection of partible items ensures convenience
                    and flexibility in shipping.we guarantee swift delivery to
                    your doorstep, ensuring your items reach you in prime
                    condition.
                  </Text>
                </Box>
              </Box>
            </Box>

            {/* second-row */}
            <Box
              display={'flex'}
              flexDir={{ base: 'column', xl: 'row' }}
              gap={'10px'}
              zIndex={10}
              ml={{ base: '0', xl: '-10' }}
              borderRadius={'md'}
            >
              {/* box-1 */}
              <Box
                width={'fit-content'}
                height={'fit-content'}
                boxShadow={'md'}
                borderRadius={'md'}
                border={'1px solid #D9D9D9'}
                px={'10px'}
                py={'40px'}
                bg={'#fff'}
              >
                <Box>
                  <HStack>
                    <Box height={'10px'} width={'100px'} background={'black'} />
                    <Text
                      fontWeight={'bold'}
                      my={'5px'}
                      fontSize={{ base: '18px', md: '18px', lg: '21px' }}
                    >
                      03
                    </Text>
                  </HStack>
                  <Text
                    fontWeight={'bold'}
                    my={'5px'}
                    fontSize={{ base: '18px', md: '18px', lg: '21px' }}
                  >
                    Standard Products
                  </Text>
                  <Text fontSize={'14px'}>
                    Our standard product offerings encompass a wide array of
                    everyday essentials, ranging from household goods to office
                    supplies. With quality and reliability at the forefront, we
                    deliver standard items promptly and efficiently, making your
                    shopping experience hassle-free.
                  </Text>
                </Box>
              </Box>
              {/* box-2 */}
              <Box
                width={'fit-content'}
                height={'fit-content'}
                boxShadow={'md'}
                borderRadius={'md'}
                border={'1px solid #D9D9D9'}
                px={'10px'}
                py={'40px'}
                bg={'#fff'}
              >
                <Box>
                  <HStack>
                    <Box height={'10px'} width={'100px'} background={'black'} />
                    <Text
                      fontWeight={'bold'}
                      my={'5px'}
                      fontSize={{ base: '18px', md: '18px', lg: '21px' }}
                    >
                      04
                    </Text>
                  </HStack>
                  <Text
                    fontWeight={'bold'}
                    my={'5px'}
                    fontSize={{ base: '18px', md: '18px', lg: '21px' }}
                  >
                    FastTrack
                  </Text>
                  <Text fontSize={'14px'}>
                    For those in need of urgent solutions, our FastTrack product
                    lineup offers expedited shipping options for time-sensitive
                    deliveries. Whether it is a last-minute gift or an emergency
                    supply, our FastTrack service ensures your items reach their
                    destination swiftly and securely.
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Layout>
  );
};

export default Productpage;

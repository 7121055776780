import React from 'react';
import Layout from '../../../layout/Layout';
import { Box, Center, Text, VStack, Flex, Image } from '@chakra-ui/react';

import assets from '../../../assets';

const sections = [
  {
    title: 'Who we are',
    image: assets.who,
    description: [
      'CENERGY is an Energy company looking at products designed to decrease carbon emissions using energy conservation principles.',
      'Skyshare is a PaaS product looking to integrate various stages and mechanisms of cargo transportation in the airline industry using a platform-based aggregation approach by integrating capacity available and demand. Over a period of time, the data collated will facilitate targeted BTL marketing outreach.',
    ],
  },
  {
    title: 'Reduce carbon emission',
    image: assets.carbon,
    description: [
      'Emissions in the Cargo sector by using the unused cargo storage available on passenger airlines that nominate their storage space on our marketplace. ​',
      'Every Cargo flight or truck that does not run on the road or fly will mean an emissions reduction in terms of not flying/running the dead weight of the truck or plane.​',
      'Ever Emissions contributed by an aircraft by Right Sizing the aircraft, increasing the load factor and reducing fuel usage per passenger',
      'Route optimization will allow for better efficiencies for fuel consumption, with more opportunity for carriers to better their margins​.​',
    ],
  },
  {
    title: 'How does it work',
    image: assets.work,
    description: [
      'The COVID-19 pandemic has shown that passenger airlines can be used to carry cargo profitably.',
      'Our research has shown that the Freight loading for popular airlines was negligible before 2020,though some operators have been generating operational income off cargo for years.',
      'There is a market space cluster that we aim to create to have all airlines on the same marketplace for space. Cargo / Trucking /Mailing companies can use the space available on aircraft for transit instead of their own vehicles / planes​.',
    ],
  },
];

const AboutUs = () => {
  return (
    <Layout>
      {/* Our Vision */}
      <Box bgGradient="linear(to-b, #7FAAC1, #3C505B)" py={'60px'} px={'25px'}>
        <VStack>
          <Text
            fontWeight={'bold'}
            color={'#fff'}
            fontSize={{ base: '2xl', md: '2xl', lg: '4xl' }}
          >
            Our Vision
          </Text>
          <Center width={{ base: '150px', md: '170px', lg: '200px' }}>
            <Image src={assets.underline} alt="background-image" />
          </Center>
        </VStack>
        <Flex
          direction={{ base: 'column', md: 'column', lg: 'row' }}
          my={'40px'}
          gap={'150px'}
          alignItems={'flex-start'}
        >
          {sections.map((section, index) => (
            <Box
              key={index}
              display={'flex'}
              alignItems={'center'}
              width={{ base: '100%', md: '100%', lg: '33%' }}
            >
              <VStack>
                <Center>
                  <Image src={section.image} alt="background-image" />
                </Center>
                <Text
                  color={'#fff'}
                  fontWeight={'bold'}
                  fontSize={{ base: '18px', md: '18px', lg: '24px' }}
                >
                  {section.title}
                </Text>
                {section.description.map((text, index) => (
                  <Text
                    key={index}
                    color={'#fff'}
                    fontSize={{ base: '14px', md: '16px' }}
                    textAlign={'center'}
                  >
                    {text}
                  </Text>
                ))}
              </VStack>
            </Box>
          ))}
        </Flex>
      </Box>

      {/* Research */}
      <Box background={'#fff'} py={'60px'} px={'25px'}>
        <VStack>
          <Text
            fontWeight={'bold'}
            color={'#3C505B'}
            fontSize={{ base: '2xl', md: '2xl', lg: '4xl' }}
          >
            Our Research
          </Text>
          <Center width={{ base: '150px', md: '170px', lg: '200px' }}>
            <Image src={assets.underline_black} alt="background-image" />
          </Center>
        </VStack>
        {/* reasearch-says */}
        <Flex
          direction={{ base: 'column', md: 'column', lg: 'row' }}
          my={'40px'}
        >
          <Box
            pl={{ base: '0%', md: '0%', lg: '5%' }}
            pt={{ base: '0%', md: '0%', lg: '6%' }}
            textAlign={{ base: 'center', md: 'center', lg: 'left' }}
            mx={{ base: 'auto', lg: 'unset' }} // Adjusting horizontal margin for centering
            order={{ base: 2, md: 2, lg: 1 }} // Changing the order for mobile and tablet screens
          >
            <Text
              color={'#3C505B'}
              fontWeight={'bold'}
              fontSize={{ base: '18px', md: '18px', lg: '24px' }}
              textDecor={'underline'}
            >
              What our Research says​
            </Text>

            <Text fontSize={{ base: '14px', md: '16px' }} mt={'2%'}>
              Freight Cargo utilization is as low as 20%​
            </Text>
            <Text fontSize={{ base: '14px', md: '16px' }} mt={'2%'}>
              Freight can bring in up to 2X more revenue per tonne of Passenger
              /Freight Tonne travelled.​​​
            </Text>
            <Text fontSize={{ base: '14px', md: '16px' }} mt={'2%'}>
              We analyzed a number of airlines and found Freight Revenue were
              small but significant, with room for improvement.​​​
            </Text>
            <Text fontSize={{ base: '14px', md: '16px' }} mt={'2%'}>
              There is no single platform for utilizing these unutilized spaces.
              ​
            </Text>
          </Box>
          <Box
            pl={{ base: '0%', md: '0%', lg: '5%' }}
            mx={{ base: 'auto', lg: 'unset' }} // Adjusting horizontal margin for centering
            order={{ base: 1, md: 1, lg: 2 }} // Changing the order for mobile and tablet screens
          >
            <Center>
              <Image
                src={assets.reacserch}
                alt="background-image"
                width={'400'}
              />
            </Center>
          </Box>
        </Flex>
        {/* methology */}
        <Flex
          direction={{ base: 'column', md: 'column', lg: 'row' }}
          my={'40px'}
        >
          <Box pl={{ base: '0%', md: '0%', lg: '5%' }} mx={'auto'}>
            <Center>
              <Image
                src={assets.methology}
                alt="background-image"
                width={'400'}
              />
            </Center>
          </Box>
          <Box
            pl={{ base: '0%', md: '0%', lg: '5%' }}
            pt={{ base: '0%', md: '0%', lg: '6%' }}
            textAlign={{ base: 'center', md: 'center', lg: 'left' }}
            mx={'auto'}
          >
            <Text
              color={'#3C505B'}
              fontWeight={'bold'}
              fontSize={{ base: '18px', md: '18px', lg: '24px' }}
              textDecor={'underline'}
            >
              Research Methodology​
            </Text>
            <Text fontSize={{ base: '14px', md: '16px' }} mt={'2%'}>
              Using APIs to extract daily and weekly Freight and plane size
              data. 
            </Text>
            <Text fontSize={{ base: '14px', md: '16px' }} mt={'2%'}>
              Create a model for A2B travel and find the freight load factor for
              the route.​​​​
            </Text>
            <Text fontSize={{ base: '14px', md: '16px' }} mt={'2%'}>
              Check for the size of the Cluster space industry and see how
              many tonnes of space is really available. ​​​
            </Text>
            <Text fontSize={{ base: '14px', md: '16px' }} mt={'2%'}>
              Understanding if we can make cargo travel faster, cheaper and with
              lower emissions per tonne of cargo flown.​ ​
            </Text>
          </Box>
        </Flex>
      </Box>
    </Layout>
  );
};

export default AboutUs;

import React from 'react';
import { Box, Flex, Text, Link, Image } from '@chakra-ui/react';
import assets from '../assets';
const Footer = () => {
  return (
    <Box
      bg="#011C2B"
      py={{ base: 8, md: 8, lg: 0 }}
      position="relative"
      zIndex={0}
    >
      <Flex
        direction={{ base: 'column', md: 'row' }}
        align={{ base: 'start', md: 'center' }}
        justify="space-between"
        px={8}
        mx="auto"
        color="white"
        zIndex={1}
      >
        {/* Left side content */}
        <Box mb={{ base: 4, md: 0 }}>
          <Text maxW={'600px'}>
            © 2024 Skyshare Cargo. Use of the Skyshare Cargo websites and our
            company information constitutes acceptance of our Terms and
            conditions
          </Text>
          <br/>
          <Text>
            Cenergy Limited 54 Berberis Walk UB77TZ COMPANIES
            HOUSE REGN : 12671205
          </Text>
          <Text mt={'5%'}>
            <Link href="/privacy-policy">Privacy Policy</Link> |{' '}
            <Link href="/code-of-conduct">Code of Conduct</Link> |{' '}
            <Link href="/contract-of-carriage">Contract of Carriage</Link> |{' '}
            <Link href="/sitemap">Sitemap</Link>
          </Text>
        </Box>
        {/* Background image */}
        <Box ml={'-10%'} zIndex={-1}>
          <Image src={assets.backimg} alt="background-image" />
        </Box>
        {/* Right side logo */}
        <Box
          className="logo-width"
          width={{ base: '400px', md: '600px', lg: '400px' }}
          mx={'auto'}
        >
          <Image src={assets.footerlogo} alt="Logo" />
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;

export const accordItem = [
  {
    title: 'Do I need an account to ship with SkyShare Cargo?',
    subtitle:
      'Yes, an account is required to utilize SkyShare Cargos shipping services. Having an account allows you to manage shipments, access tracking information, and enjoy other benefits such as discounted rates and personalized support.',
  },
  {
    title: 'How do customers or businesses open a SkyShare Cargo account?',
    subtitle:
      'Opening a SkyShare Cargo account is easy and straightforward. Simply visit our website and navigate to the registration page. From there, you can follow the prompts to provide the necessary information, such as your contact details, business information (if applicable), and shipping preferences. Once submitted, our team will review your application and approve your account.',
  },
  {
    title: 'How long does it take for my account to be approved?',
    subtitle:
      'The approval process for a SkyShare Cargo account typically takes 1-2 business days. During this time, our team verifies the information provided in your application and ensures that you meet our eligibility criteria. Once approved, you will receive an email confirmation with instructions on how to access your account and start shipping.',
  },
  {
    title: 'How do customers or businesses open a SkyShare Cargo account?',
    subtitle:
      'Opening a SkyShare Cargo account is a simple process that begins with visiting our website. From there, you can navigate to the registration page and fill out the required fields, including your contact information, company details (if applicable), and shipping preferences. Once your application is submitted, our team will review it promptly and notify you of your account status.',
  },
  {
    title: ' Do I need an account to ship with SkyShare Cargo?',
    subtitle:
      ' Yes, having an account is essential for utilizing SkyShare Cargos shipping services. With an account, you gain access to a range of features and benefits, including easy shipment management, tracking capabilities, and special discounts. Plus, having an account allows us to provide you with personalized support and assistance throughout the shipping process.',
  },
];

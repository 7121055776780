import React, { useState } from 'react';

import {
  VStack,
  Flex,
  Box,
  Text,
  HStack,
  Input,
  FormLabel,
  Button,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import {
  setDepartureData,
  setArrivalData,
  setCarrierCodes,
  setDate,
  setTime,
} from '../../../../store/slices/flightSearchSlice';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import GenericModal from '../../../../components/GenricModal';
import Cargoform from '../cargo_form/Cargoform';
import airport from '../../../../data/airports.json';
import airlines from '../../../../data/airlines.json';
import { PiAirplaneTiltFill } from 'react-icons/pi';
import GenericInput from '../../../../components/GenericInput';

const validationSchema = Yup.object().shape({
  departureCode: Yup.string().required('Departure code is required'),
  arrivalCode: Yup.string().required('Arrival code is required'),
  booking_date: Yup.string().required('Date is required'),
  booking_time: Yup.string().required('Time is required'),
  includedCarrierCodes: Yup.string().required('CarrierCodes code is required'),
});

const SearchInput = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [departure, setDeparture] = useState('');
  const [arrival, setArrival] = useState('');
  const [airline, setAirlines] = useState('');

  const [is1DropdownVisible, setIs1DropdownVisible] = useState(false);
  const [is2DropdownVisible, setIs2DropdownVisible] = useState(false);
  const [is3DropdownVisible, setIs3DropdownVisible] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month: string | number = today.getMonth() + 1;
    let day: string | number = today.getDate();
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
    return `${year}-${month}-${day}`;
  };

  const filterDataDeparture = airport.filter((data) => {
    if (departure === '') {
      return false;
    } else if (data.code.toUpperCase().includes(departure.toUpperCase())) {
      return true;
    } else {
      return false;
    }
  });

  const filterDataArrival = airport.filter((data) => {
    if (arrival === '') {
      return false;
    } else if (data.code.toUpperCase().includes(arrival.toUpperCase())) {
      return true;
    } else {
      return false;
    }
  });

  const filterCarrierCode = airlines?.filter((data) => {
    if (airline === '') {
      return false;
    } else if (data.code.toUpperCase().includes(airline.toUpperCase())) {
      return true;
    } else {
      return false;
    }
  });

  return (
    <>
      <Formik
        initialValues={{
          departureCode: '',
          arrivalCode: '',
          booking_date: '',
          booking_time: '',
          includedCarrierCodes: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          dispatch(setDepartureData(values.departureCode));
          dispatch(setArrivalData(values.arrivalCode));
          dispatch(setDate(values.booking_date));
          dispatch(setTime(values.booking_time));
          dispatch(setCarrierCodes(values.includedCarrierCodes));
          localStorage.setItem('flightForm', JSON.stringify(values));
          setIsOpen(true);
          resetForm();
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <Box
            width={{ base: '100%', md: '60%' }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={'20px'}
          >
            <Form onSubmit={handleSubmit}>
              <VStack spacing={'20px'}>
                <Text
                  textAlign={'center'}
                  color="#fff"
                  fontSize={{ base: '2xl', md: '2xl', lg: '3xl' }}
                  textDecor={'underline'}
                  mb={'30px'}
                >
                  Flight Schedule
                </Text>

                <Flex
                  flexDirection={{
                    base: 'column',
                    md: 'column',
                    lg: 'column',
                    xl: 'row',
                  }}
                  gap={'20px'}
                >
                  {/*departure*/}
                  <Box width={{ base: '100%', md: 'sm' }} position="relative">
                    <FormLabel
                      color={'#fff'}
                      fontSize={'14px'}
                      fontWeight={'lighter'}
                      marginBottom={'5px'}
                    >
                      Departure city or airport code
                    </FormLabel>
                    <Input
                      mt="10px"
                      type="search"
                      variant={'solid'}
                      placeholder="city code"
                      width={{ base: 'xs', md: 'sm' }}
                      value={values.departureCode}
                      onChange={(e) => {
                        handleChange(e);
                        setDeparture(e.target.value);
                        setIs1DropdownVisible(true);
                      }}
                      name="departureCode"
                      isRequired
                    />
                    {is1DropdownVisible && (
                      <Box
                        position="absolute"
                        top="100%"
                        left={0}
                        width={{ base: '100%', md: '100%' }}
                        background={'#fff'}
                        maxHeight={'200px'}
                        overflowY={'scroll'}
                        zIndex={10}
                        cursor={'pointer'}
                      >
                        {filterDataDeparture?.map((item, index) => (
                          <HStack
                            key={index}
                            mb={'10px'}
                            onClick={() => {
                              setFieldValue('departureCode', item.code);
                              setDeparture(item.code);
                              setIs1DropdownVisible(false);
                            }}
                            _hover={{ backgroundColor: 'gray.100' }}
                          >
                            <Box pl={'1%'} width={'10%'}>
                              <PiAirplaneTiltFill />
                            </Box>

                            <VStack alignItems={'flex-start'} width={'70%'}>
                              <Text fontSize={'lg'} fontWeight={'bold'}>
                                {item.country}
                              </Text>
                              <Text
                                fontSize={'sm'}
                                color={'gray.400'}
                                fontWeight={'normal'}
                                maxWidth={'300px'}
                                mt={'-10px'}
                              >
                                {item.name}
                              </Text>
                            </VStack>
                            <Box width={'20%'}>
                              <Text
                                fontSize={'lg'}
                                fontWeight={'bold'}
                                color={'gray.400'}
                              >
                                {item.code}
                              </Text>
                            </Box>
                          </HStack>
                        ))}
                      </Box>
                    )}
                  </Box>
                  {/* arrival */}
                  <Box width={{ base: '100%', md: 'sm' }} position="relative">
                    <FormLabel
                      color={'#fff'}
                      fontSize={'14px'}
                      fontWeight={'lighter'}
                      marginBottom={'5px'}
                    >
                      Arrival city or airport code
                    </FormLabel>
                    <Input
                      mt="10px"
                      type="search"
                      variant={'solid'}
                      placeholder="city code"
                      width={{ base: 'xs', md: 'sm' }}
                      value={values.arrivalCode}
                      onChange={(e) => {
                        handleChange(e);
                        setArrival(e.target.value);
                        setIs2DropdownVisible(true);
                      }}
                      name="arrivalCode"
                      isRequired
                    />
                    {is2DropdownVisible && (
                      <Box
                        position="absolute"
                        top="100%"
                        left={0}
                        width={{ base: '100%', md: '100%' }}
                        background={'#fff'}
                        maxHeight={'200px'}
                        overflowY={'scroll'}
                        zIndex={10}
                        cursor={'pointer'}
                      >
                        {filterDataArrival?.map((item, index) => (
                          <HStack
                            key={index}
                            mb={'10px'}
                            onClick={() => {
                              setFieldValue('arrivalCode', item.code);
                              setArrival(item.code);
                              setIs2DropdownVisible(false);
                            }}
                            _hover={{ backgroundColor: 'gray.100' }}
                          >
                            <Box pl={'1%'} width={'10%'}>
                              <PiAirplaneTiltFill />
                            </Box>

                            <VStack alignItems={'flex-start'} width={'70%'}>
                              <Text fontSize={'lg'} fontWeight={'bold'}>
                                {item.country}
                              </Text>
                              <Text
                                fontSize={'sm'}
                                color={'gray.400'}
                                fontWeight={'normal'}
                                maxWidth={'300px'}
                                mt={'-10px'}
                              >
                                {item.name}
                              </Text>
                            </VStack>
                            <Box width={'20%'}>
                              <Text
                                fontSize={'lg'}
                                fontWeight={'bold'}
                                color={'gray.400'}
                              >
                                {item.code}
                              </Text>
                            </Box>
                          </HStack>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Flex>
                <Flex
                  flexDirection={{
                    base: 'column',
                    md: 'column',
                    lg: 'column',
                    xl: 'row',
                  }}
                  gap={'20px'}
                >
                  {/*date*/}
                  <GenericInput
                    mt="10px"
                    type="date"
                    label="Departure date"
                    variant={'solid'}
                    width={{ base: 'xs', md: 'sm' }}
                    name="booking_date"
                    min={getCurrentDate()}
                    value={values.booking_date}
                    onChange={handleChange}
                  />
                  {/*time*/}
                  <GenericInput
                    mt="10px"
                    type="time"
                    label="Departure time"
                    variant={'solid'}
                    width={{ base: 'xs', md: 'sm' }}
                    name="booking_time"
                    value={values.booking_time}
                    onChange={handleChange}
                  />
                </Flex>
                {/* airlines-code */}
                <Box width={{ base: '100%', md: 'sm' }} position="relative">
                  <FormLabel
                    color={'#fff'}
                    fontSize={'14px'}
                    fontWeight={'lighter'}
                    marginBottom={'5px'}
                  >
                    Airlines or airlines code
                  </FormLabel>
                  <Input
                    mt="10px"
                    type="search"
                    variant={'solid'}
                    placeholder="airline code"
                    width={{ base: 'xs', md: 'sm' }}
                    value={values.includedCarrierCodes}
                    onChange={(e) => {
                      handleChange(e);
                      setAirlines(e.target.value);
                      setIs3DropdownVisible(true);
                    }}
                    name="includedCarrierCodes"
                    isRequired
                  />
                  {is3DropdownVisible && (
                    <Box
                      position="absolute"
                      top="100%"
                      left={0}
                      width={{ base: '100%', md: '100%' }}
                      background={'#fff'}
                      maxHeight={'200px'}
                      overflowY={'scroll'}
                      zIndex={10}
                      cursor={'pointer'}
                    >
                      {filterCarrierCode?.map((item, index) => (
                        <HStack
                          key={index}
                          mb={'10px'}
                          onClick={() => {
                            setFieldValue('includedCarrierCodes', item.code);
                            setAirlines(item.code);
                            setIs3DropdownVisible(false);
                          }}
                          _hover={{ backgroundColor: 'gray.100' }}
                        >
                          <Box pl={'1%'} width={'10%'}>
                            <PiAirplaneTiltFill />
                          </Box>

                          <VStack alignItems={'flex-start'} width={'70%'}>
                            <Text fontSize={'lg'} fontWeight={'bold'}>
                              {item.country}
                            </Text>
                            <Text
                              fontSize={'sm'}
                              color={'gray.400'}
                              fontWeight={'normal'}
                              maxWidth={'300px'}
                              mt={'-10px'}
                            >
                              {item.name}
                            </Text>
                          </VStack>
                          <Box width={'20%'}>
                            <Text
                              fontSize={'lg'}
                              fontWeight={'bold'}
                              color={'gray.400'}
                            >
                              {item.code}
                            </Text>
                          </Box>
                        </HStack>
                      ))}
                    </Box>
                  )}
                </Box>
                {/* search_btn */}
                <Box my={'30px'}>
                  <Button
                    backgroundColor="#fff"
                    color="black"
                    width="170px"
                    type="submit"
                  >
                    Search
                  </Button>
                </Box>
              </VStack>
            </Form>
          </Box>
        )}
      </Formik>
      <GenericModal isOpen={isOpen} onClose={handleClose} size="4xl">
        <Cargoform onClose={handleClose} />
      </GenericModal>
    </>
  );
};

export default SearchInput;

import React from 'react';
import { Image, Heading, Text, Button, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import assets from '../../assets';

const PageNotFound = () => {
  return (
    <Flex direction="column" align="center" mt={5}>
      <Image src={assets.error} alt="Error Image" />
      <Heading as="h1" size="xl" mt={4}>
        Oops! Page Not Found
      </Heading>
      <Text fontSize="lg" mt={2}>
        The page you are looking for does not exist.
      </Text>
      <Link to="/">
        <Button mt={4} colorScheme="blue">
          Back to Home
        </Button>
      </Link>
    </Flex>
  );
};

export default PageNotFound;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  useSteps,
  Button,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AddressForm from './AddressForm';
import ConfirmPage from './Confirm-Page';
import UserLocation from './UserLoaction';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setSenderName,
  setSenderEmail,
  setSenderAddress,
  setSenderContact,
  setSenderCity,
  setSenderPincode,
  setSenderState,
  setSenderCountry,
  setConsigneeName,
  setConsigneeEmail,
  setConsigneeAddress,
  setConsigneeContact,
  setConsigneeCity,
  setConsigneePincode,
  setConsigneeState,
  setConsigneeCountry,
} from '../../store/slices/waybillSlice';
// Validation schemas for each step
const validationSchema = [
  null, // No validation schema for step 0
  Yup.object({
    pickupName: Yup.string().required('Name is required'),
    pickupAddressLine1: Yup.string().required('Address Line 1 is required'),
    pickupCity: Yup.string().required('City is required'),
    pickupState: Yup.string().required('State/Province is required'),
    pickupPostalCode: Yup.string().required('Postal/ZIP Code is required'),
    pickupCountry: Yup.string().required('Country is required'),
    pickupContactNumber: Yup.string().required('Contact Number is required'),
    pickupEmail: Yup.string().required('Email is required'),
  }),
  Yup.object({
    deliveryName: Yup.string().required('Name is required'),
    deliveryAddressLine1: Yup.string().required('Address Line 1 is required'),
    deliveryCity: Yup.string().required('City is required'),
    deliveryState: Yup.string().required('State/Province is required'),
    deliveryPostalCode: Yup.string().required('Postal/ZIP Code is required'),
    deliveryCountry: Yup.string().required('Country is required'),
    deliveryContactNumber: Yup.string().required('Contact Number is required'),
    deliveryEmail: Yup.string().required('Email is required'),
  }),
  null, // No validation schema for step 3
];

// Step titles and descriptions
const steps = [
  { title: 'Step 1', description: 'User Location' },
  { title: 'Step 2', description: 'Pickup Address' },
  { title: 'Step 3', description: 'Delivery Address' },
  { title: 'Step 4', description: 'Address Confirmation' },
];

const MultiStepForm = () => {
  const navigate = useNavigate();
  const { address } = useSelector((state: any) => state.location);
  const toast = useToast();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [preselectedAddress, setPreselectedAddress] = useState({
    pickupCity: '',
    pickupState: '',
    pickupPostalCode: '',
    pickupCountry: '',
    pickupAddressLine1: '',
    pickupAddressLine2: '',
  });
  const { activeStep, setActiveStep } = useSteps({
    index: step,
    count: steps.length,
  });
  useEffect(() => {
    if (address) {
      setPreselectedAddress({
        pickupCity: address?.city || '',
        pickupState: address?.state || '',
        pickupPostalCode: address?.postcode || '',
        pickupCountry: address?.country || '',
        pickupAddressLine1: [
          address?.house_number,
          address?.neighbourhood,
          address?.building !== 'undefined' ? address?.building : '',
        ]
          .filter(Boolean)
          .join(', '),
        pickupAddressLine2: [
          address?.road !== 'unnamed road' ? address?.road : '',
          address?.quarter !== 'undefined' ? address?.quarter : '',
        ]
          .filter(Boolean)
          .join(', '),
      });
    }
  }, [address, activeStep]);

  const handleNext = (values: any, actions: any) => {
    if (step < validationSchema.length - 1) {
      setStep(step + 1);
      setActiveStep(step + 1);
    } else {
      toast({
        title: 'Form submitted!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
      actions.resetForm();
      // setStep(0);
      setActiveStep(0);
      navigate('/billpayment');
    }
    actions.setTouched({});
  };

  const handlePrev = () => {
    setStep(step - 1);
    setActiveStep(step - 1);
  };

  return (
    <Box
      display="flex"
      flexDirection={{ base: 'column', lg: 'row' }}
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width={'100%'}
      background="#f0f0f0"
      padding="2%"
    >
      <Box
        display={'flex'}
        flexDirection={{ base: 'column', lg: 'row' }}
        padding={'2%'}
        background={'#fff'}
        alignItems={'center'}
        boxShadow={'lg'}
        borderRadius={'10px'}
        width={'100%'}
      >
        <Box
          boxShadow={'lg'}
          width={{ base: '100%', lg: '40%' }}
          background={'#3C505B'}
          padding={'2.5%'}
          borderRadius={{ base: '10px 10px 0 0', lg: '10px 0 0 10px' }}
        >
          <Link to="/">
            <Text color={'gray.100'} fontSize={'2xl'} fontWeight={'bold'}>
              SkyShare Cargo
            </Text>
          </Link>
          <Text
            color={'gray.100'}
            fontSize={'lg'}
            fontWeight={'medium'}
            mt={'50px'}
          >
            Steps
          </Text>
          <Text mt={'20px'} fontSize={'16px'} color={'gray.300'}>
            Fill in all the required information for the current step using the
            provided inputs and proceed to the next step.
          </Text>
          <Stepper
            index={activeStep}
            orientation="vertical"
            height="360px"
            gap="0"
            mt={'20px'}
          >
            {steps.map((stepItem, index) => (
              <Step key={index}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>
                <Box flexShrink="0">
                  <StepTitle style={{ color: '#fff' }}>
                    {stepItem.title}
                  </StepTitle>
                  <StepDescription style={{ color: '#fff' }}>
                    {stepItem.description}
                  </StepDescription>
                </Box>
                <StepSeparator />
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box
          borderWidth={1}
          boxShadow={'lg'}
          width={{ base: '100%', lg: '60%' }}
          background={'#fff'}
          borderRadius={{ base: '0 0 10px 10px', lg: '0 10px 10px 0' }}
          padding={'2%'}
        >
          <Formik
            initialValues={{
              pickupName: '',
              pickupAddressLine1: preselectedAddress?.pickupAddressLine1,
              pickupAddressLine2: preselectedAddress?.pickupAddressLine2,
              pickupCity: preselectedAddress?.pickupCity,
              pickupState: preselectedAddress?.pickupState,
              pickupPostalCode: preselectedAddress?.pickupPostalCode,
              pickupCountry: preselectedAddress?.pickupCountry,
              pickupContactNumber: '',
              pickupEmail: '',
              deliveryName: '',
              deliveryAddressLine1: '',
              deliveryAddressLine2: '',
              deliveryCity: '',
              deliveryState: '',
              deliveryPostalCode: '',
              deliveryCountry: '',
              deliveryContactNumber: '',
              deliveryEmail: '',
            }}
            enableReinitialize={true}
            validationSchema={validationSchema[step]}
            onSubmit={handleNext}
          >
            {({ values }) => {
              dispatch(setSenderName(values.pickupName));
              dispatch(setSenderEmail(values.pickupEmail));
              dispatch(setSenderContact(values.pickupContactNumber));
              dispatch(
                setSenderAddress(
                  values.pickupAddressLine1 + ' ' + values.pickupAddressLine2,
                ),
              );
              dispatch(setSenderState(values.pickupState));
              dispatch(setSenderPincode(values.pickupPostalCode));
              dispatch(setSenderCity(values.pickupCity));
              dispatch(setSenderCountry(values.pickupCountry));

              dispatch(setConsigneeName(values.deliveryName));
              dispatch(setConsigneeEmail(values.deliveryEmail));
              dispatch(setConsigneeContact(values.deliveryContactNumber));
              dispatch(
                setConsigneeAddress(
                  values.deliveryAddressLine1 +
                    ' ' +
                    values.deliveryAddressLine2,
                ),
              );
              dispatch(setConsigneeState(values.deliveryState));
              dispatch(setConsigneePincode(values.deliveryPostalCode));
              dispatch(setConsigneeCity(values.deliveryCity));
              dispatch(setConsigneeCountry(values.deliveryCountry));
              return (
                <Form>
                  <Stack spacing={6}>
                    {step === 0 && <UserLocation />}
                    {step === 1 && (
                      <AddressForm
                        formTitle="Pickup Address"
                        namePrefix="pickup"
                      />
                    )}
                    {step === 2 && (
                      <AddressForm
                        formTitle="Delivery Address"
                        namePrefix="delivery"
                      />
                    )}
                    {step === 3 && <ConfirmPage values={values} />}

                    <Stack direction="row" spacing={4} justify="flex-end">
                      {step > 0 && <Button onClick={handlePrev}>Back</Button>}
                      <Button type="submit">
                        {step === 3 ? 'Submit' : 'Next'}
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default MultiStepForm;

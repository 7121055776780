import assets from '../assets';
export const listItem = [
  {
    id: '1',
    number: '01',
    title: 'Apply',
    subtitle:
      'Initiate your journey with Skyshare Cargo by completing our application process to become a valued customer.',
    btntitle: 'Apply',
    img: assets.apply,
    btnExit: true,
  },
  {
    id: '2',
    number: '02',
    title: 'Get Approved',
    subtitle:
      'Your application will undergo swift processing, typically within 7-10 business days. Upon approval, you will gain access to our efficient shipping services via Skyshare Cargo.',
    btntitle: 'Apply',
    img: assets.approved,
    btnExit: false,
  },
  {
    id: '3',
    number: '03',
    title: 'Choose your product ',
    subtitle:
      'Indicate your desired pace, and leave the rest to our acclaimed team of Skyshare Cargo Employees, renowned for their excellence in service.',
    btntitle: 'View Product',
    img: assets.choose_product,
    btnExit: true,
  },
  {
    id: '4',
    number: '04',
    title: 'Fill Out The Universal Air Waybill',
    subtitle:
      'Simplify your shipping experience by pre-filling a universal air waybill with the destination details of your shipment before arriving at our facility. For international shipments, advance booking is required, and you can easily arrange this by reaching out to your designated General Sales Agent.',
    btntitle: 'Universal Air Waybill ',
    img: assets.waybill,
    btnExit: true,
  },
  {
    id: '5',
    number: '05',
    title: 'Track Your Shipment ',
    subtitle:
      'Stay informed and in control every step of the way by enrolling in our real-time notification system. Receive instant updates on the status of your air waybill, ensuring peace of mind regarding the whereabouts of your valuable cargo.',
    btntitle: 'Track Your Shipment',
    img: assets.track,
    btnExit: true,
  },
];

export const accordItem = [
  {
    title: 'How to pickup a shipment?',
    subtitle:
      'Learn the comprehensive steps required to successfully pick up your shipment, including necessary documentation, location specifics, and time management strategies to ensure a smooth and efficient pickup process.',
  },
  {
    title: 'Accept form and payment?',
    subtitle:
      'Understand the detailed process of filling out acceptance forms and handling payments, including the types of forms needed, common mistakes to avoid, and best practices for ensuring accurate and secure transactions.',
  },
  {
    title: 'Shipment date and time?',
    subtitle:
      'Find out how to schedule and track the shipment date and time effectively, including tips on coordinating with carriers, understanding delivery windows, and using tracking tools to monitor the progress of your shipment.',
  },
  {
    title: 'Packaging Delivery?',
    subtitle:
      'Get comprehensive tips and guidelines on how to package items properly for delivery, including choosing the right materials, ensuring item safety during transit, and complying with shipping regulations to avoid delays or damages.',
  },
];

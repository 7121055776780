// authUtils.js
export const login = (accessToken: string) => {
  localStorage.setItem('accessToken', accessToken);
};

export const logout = () => {
  localStorage.removeItem('accessToken');
};

export const isAuthenticated = () => {
  return !!localStorage.getItem('accessToken');
};

export const cargoProductOptions = [
  { value: 'raw_materials', label: 'Raw Materials' },
  { value: 'electronics', label: 'Electronics' },
  { value: 'machinery', label: 'Machinery' },
  { value: 'automotive_parts', label: 'Automotive Parts' },
  { value: 'chemicals', label: 'Chemicals' },
  { value: 'pharmaceuticals', label: 'Pharmaceuticals' },
  { value: 'perishable_goods', label: 'Perishable Goods' },
  { value: 'textiles', label: 'Textiles' },
  { value: 'furniture', label: 'Furniture' },
  { value: 'construction_materials', label: 'Construction Materials' },
  { value: 'household_goods', label: 'Household Goods' },
  { value: 'clothing_apparel', label: 'Clothing and Apparel' },
  { value: 'food_beverages', label: 'Food and Beverages' },
  { value: 'plastics', label: 'Plastics' },
  { value: 'paper_products', label: 'Paper Products' },
];

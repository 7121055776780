import ellipse from './Ship/Ellipse.png';
import airolane from './Ship/airoplane.png';
import f_image from './Ship/f_schedule.png';
import firstImage from './Ship/img1.png';
import secondImage from './Ship/img2.png';
import logo1 from './Ship/icon-1.png';
import logo2 from './Ship/icon-2.png';
import backimg from './footer/footer_img.svg';
import who from './about_us/who.svg';
import carbon from './about_us/carbon.svg';
import work from './about_us/work.svg';
import underline from './about_us/Vector.png';
import methology from './about_us/methology.png';
import reacserch from './about_us/reasearch.png';
import underline_black from './about_us/underline_black.svg';
import track_shipmnet from './track_your_shipment/shipment.png';
import footerlogo from './footer/logo.svg';
import circle from './contact_us/Vector.png';
import product from './product/product.png';
import error from './web_computer-error-concept_credit_istock-165182593.jpg';
import payment from './payment/payment.png';
import master from './payment/logos_mastercard.svg';
import visa from './payment/logos_visa.svg';
import discover from './payment/logos_discover.svg';
import express from './payment/american.svg';
import how_to_ship from './how_to_ship/how-sship.png';
import apply from './how_to_ship/apply.svg';
import approved from './how_to_ship/approved.png';
import waybill from './how_to_ship/air-waybill.png';
import choose_product from './how_to_ship/chhose-product.png';
import track from './how_to_ship/track.png';
import booking from './booking/booking.png';
import duration from './booking/duration.png';

const assets = {
  ellipse,
  airolane,
  f_image,
  firstImage,
  secondImage,
  logo1,
  logo2,
  backimg,
  who,
  carbon,
  work,
  underline,
  methology,
  reacserch,
  underline_black,
  track_shipmnet,
  footerlogo,
  circle,
  product,
  error,
  payment,
  master,
  visa,
  discover,
  express,
  how_to_ship,
  apply,
  approved,
  waybill,
  choose_product,
  track,
  booking,
  duration,
};

export default assets;

import React from 'react';
import {
  Box,
  Text,
  Heading,
  Flex,
  Button,
  Image,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { BiLeftArrow, BiLeftArrowAlt } from 'react-icons/bi';

const SuccessPage = () => {
  const navigate = useNavigate();

  return (
    <Flex
      h="100vh"
      align="center"
      justify="center"
      direction="column"
      p={5}
      bgGradient="linear(to-r, teal.500, blue.500)"
    >
      <VStack
        spacing={5}
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="lg"
        align="center"
      >
        <Image
          src="/success.jpg"
          alt="Success"
          boxSize="200px"
          objectFit="cover"
          borderRadius="full"
          border="4px solid teal"
        />
        <HStack spacing={3} align="center">
          <FaCheckCircle size={40} color="green" />
          <Heading size="lg">Payment Successful!</Heading>
        </HStack>
        <Text fontSize="lg" color="gray.600">
          Thank you for your payment.
        </Text>

        <Button
          mt={4}
          colorScheme="teal"
          size="lg"
          as="a"
          href="/airwaybill.pdf"
          download
        >
          Download Air Waybill
        </Button>

        <HStack cursor={'pointer'} onClick={() => navigate('/')}>
          <BiLeftArrowAlt size={24} color="teal" />
          <Text color="teal"> Go Back to Home Page</Text>
        </HStack>
      </VStack>
    </Flex>
  );
};

export default SuccessPage;

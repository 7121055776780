import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hook/hooks';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { FaMapMarkerAlt } from 'react-icons/fa';
import ReactDOMServer from 'react-dom/server';
import { Box } from '@chakra-ui/react';
import {
  setLocation,
  setError,
  fetchAddress,
} from '../../store/slices/locationSlice';


const createCustomIcon = (icon: React.ReactNode) => {
  return L.divIcon({
    className: 'custom-icon',
    html: ReactDOMServer.renderToString(icon),
    iconSize: [32, 32],
    iconAnchor: [16, 32],
  });
};

const CustomMarker = ({ position }: { position: [number, number] }) => {
  const dispatch = useAppDispatch();
  const icon = createCustomIcon(<FaMapMarkerAlt size={32} color="red" />);
  useMapEvents({
    click(e) {
      const { lat, lng } = e.latlng;
      dispatch(setLocation({ latitude: lat, longitude: lng }));
      dispatch(fetchAddress({ latitude: lat, longitude: lng }));
    },
  });

  return <Marker position={position} icon={icon} />;
};

const UserLocation = () => {
  const dispatch = useAppDispatch();
  const { latitude, longitude, error } = useAppSelector(
    (state: any) => state.location,
  );
  const [position, setPosition] = useState<[number, number] | null>(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, geoError);
    } else {
      dispatch(setError('Geolocation is not supported by this browser.'));
    }
  }, [dispatch]);

  const success = (position: any) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setPosition([latitude, longitude]);
    dispatch(setLocation({ latitude, longitude }));
    dispatch(fetchAddress({ latitude, longitude }));
  };

  const geoError = () => {
    dispatch(setError('Unable to retrieve your location.'));
  };

  useEffect(() => {
    if (latitude && longitude) {
      setPosition([latitude, longitude]);
    }
  }, [latitude, longitude]);

  return (
    <Box my={'.8%'}>
      {position ? (
        <MapContainer
          center={position}
          zoom={15}
          style={{ height: '500px', width: '100%' }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <CustomMarker position={position} />
        </MapContainer>
      ) : (
        'Loading...'
      )}
      {error && <p>{error}</p>}
    </Box>
  );
};

export default UserLocation;

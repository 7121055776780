import { configureStore } from '@reduxjs/toolkit';
import flightSearchSlice from './slices/flightSearchSlice';
import locationReducer from './slices/locationSlice';
import { bookingApi } from './slices/bookingApiSlice';
import { registrationApi } from './slices/registrationApiSlice';
import { loginApi } from './slices/loginApiSlice';
import waybillReducer from './slices/waybillSlice';
import filterDataSlice from './slices/filterDataSlice';

const store = configureStore({
  reducer: {
    searchFlight: flightSearchSlice,
    location: locationReducer,
    [bookingApi.reducerPath]: bookingApi.reducer,
    [registrationApi.reducerPath]: registrationApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    filterData: filterDataSlice,
    waybill: waybillReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      bookingApi.middleware,
      registrationApi.middleware,
      loginApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

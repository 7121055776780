import React from 'react';
import { cargoProductOptions } from '../../../../data/cargoProductOptions';
import { Field, Form, Formik } from 'formik';
import {
  Box,
  Button,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
} from '@chakra-ui/react';

import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../components/LoaderComponent';
import axios from 'axios';
import { isAuthenticated } from '../../../../util/AuthUtils';
import { useDispatch } from 'react-redux';
import {
  setProduct,
  setCargoHeight,
  setCargoLength,
  setCargoWidth,
  setCargoWeight,
} from '../../../../store/slices/waybillSlice';
import { ArrowForwardIcon } from '@chakra-ui/icons';
const validationSchema = Yup.object().shape({
  cargo_product_description: Yup.string().required('Product is required'),
  cargo_product_height: Yup.string().required('Product height is required'),
  cargo_product_length: Yup.string().required('Product length is required'),
  cargo_product_width: Yup.string().required('Product width is required'),
  cargo_product_weight: Yup.string().required('Product weight is required'),
});

interface SearchFlightState {
  arrivalCode: string;
  departureCode: string;
  includedCarrierCodes: string;
  date: string;
  time: string;
}

interface RootState {
  searchFlight: SearchFlightState;
}

interface CargoformProps {
  onClose: () => void;
}

const Cargoform: React.FC<CargoformProps> = ({ onClose }) => {
  const rootState: RootState = useSelector((state: RootState) => state);
  const searchFlight = rootState.searchFlight;
  const arrivalCode = searchFlight.arrivalCode;
  const departureCode = searchFlight.departureCode;
  const date = searchFlight.date;
  const time = searchFlight.time;
  const includedCarrierCodes = searchFlight.includedCarrierCodes;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken');
  const toast = useToast();

  const checkUser = () => {
    if (isAuthenticated()) {
      return true;
    } else {
      toast({
        position: 'top',
        duration: 1500,
        isClosable: true,
        render: () => (
          <Box
            color="white"
            p={4}
            bgGradient="linear(to-r, red.400, red.600)"
            borderRadius="lg"
            boxShadow="lg"
            textAlign="center"
            maxW="md"
            mx="auto"
          >
            <Text fontSize="lg" fontWeight="bold" mb={4}>
              You are not logged in
            </Text>

            <Button
              onClick={() => {
                navigate('/login');
              }}
              variant="solid"
              bg="white"
              color="red.500"
              _hover={{ bg: 'gray.100' }}
              _active={{ bg: 'gray.200' }}
              borderRadius="full"
              rightIcon={<ArrowForwardIcon fontSize="24px" />}
              px={6}
              boxShadow={'lg'}
            >
              Login
            </Button>
          </Box>
        ),
      });
    }
  };

  return (
    <Formik
      initialValues={{
        departureCode: departureCode,
        arrivalCode: arrivalCode,
        booking_date: date,
        booking_time: time,
        includedCarrierCodes: includedCarrierCodes,
        cargo_product_description: '',
        cargo_product_height: '',
        cargo_product_length: '',
        cargo_product_width: '',
        cargo_product_weight: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        if (checkUser()) {
          try {
            const response = await axios.post(
              'https://management.azure.com/subscriptions/072596b4-f2a8-48d0-b609-a1177b7adead/resourceGroups/Skyshare/providers/Microsoft.DataFactory/factories/skyshareflightdata/pipelines/AllFlightData_Testing_bs_copy1/createRun?api-version=2018-06-01',
              {
                departureCode: values.departureCode,
                arrivalCode: values.arrivalCode,
                booking_date: values.booking_date,
                booking_time: values.booking_time,
                cargo_product_description: values.cargo_product_description,
                cargo_product_height: values.cargo_product_height,
                cargo_product_length: values.cargo_product_length,
                cargo_product_width: values.cargo_product_width,
                cargo_product_weight: values.cargo_product_weight,
                originalLocation: values.departureCode,
                destinationLocation: values.arrivalCode,
                flightDate: values.booking_date,
                includedCarrierCodes: values.includedCarrierCodes,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${accessToken}`,
                },
              },
            );
            dispatch(setProduct(values.cargo_product_description));
            dispatch(setCargoHeight(values.cargo_product_height));
            dispatch(setCargoLength(values.cargo_product_length));
            dispatch(setCargoWidth(values.cargo_product_width));
            dispatch(setCargoWeight(values.cargo_product_weight));
            console.log(response.data);
            resetForm();
            onClose();
            navigate('/booking'); // Navigate after form submission is successful
          } catch (error: any) {
            console.error('Error calling second API', error);
            toast({
              title: `${error.code} login required.`,
              description: 'token expired.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
          }
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box
            display="flex"
            flexDir={{ base: 'column', md: 'row' }}
            alignItems="center"
          >
            <Box
              background="#fff"
              width={{ base: '100%', md: '40%' }}
              mb={{ base: '5%', md: '0%' }}
              padding="2%"
            >
              <Text fontSize={{ base: '18px', md: '21px' }} fontWeight="bold">
                Fill the form with required inputs and proceed with your
                booking.
              </Text>
            </Box>
            <Box
              boxShadow="md"
              mt="-2%"
              background="#3C505B"
              width={{ base: '100%', md: '60%' }}
              paddingX="2%"
              paddingY="5%"
            >
              <VStack spacing="10px">
                <Text mt="-10px" fontSize="18px" color="#fff">
                  In compliance with safety regulations, SkyShare Cargo Services
                  strictly prohibits the transportation of hazardous products.
                </Text>
                <FormControl isRequired>
                  <FormLabel color="#fff">Products</FormLabel>
                  <Field
                    as={Select}
                    _focus={{ background: '#fff' }}
                    variant="filled"
                    placeholder="Select material type"
                    name="cargo_product_description"
                  >
                    {cargoProductOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel color="#fff">Cargo height</FormLabel>
                  <Field
                    as={Input}
                    _focus={{ background: '#fff' }}
                    variant="filled"
                    placeholder="cm"
                    name="cargo_product_height"
                    type="number"
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel color="#fff">Cargo length</FormLabel>
                  <Field
                    as={Input}
                    _focus={{ background: '#fff' }}
                    variant="filled"
                    placeholder="inch"
                    name="cargo_product_length"
                    type="number"
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel color="#fff">Cargo width</FormLabel>
                  <Field
                    as={Input}
                    _focus={{ background: '#fff' }}
                    variant="filled"
                    placeholder="cm"
                    name="cargo_product_width"
                    type="number"
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel color="#fff">Cargo weight</FormLabel>
                  <Field
                    as={Input}
                    _focus={{ background: '#fff' }}
                    variant="filled"
                    placeholder="kg"
                    name="cargo_product_weight"
                    type="number"
                  />
                </FormControl>

                <Button
                  color="#fff"
                  variant="outline"
                  _hover={{ background: 'transparent' }}
                  _pressed={{ background: 'transparent' }}
                  marginTop={'10px'}
                  type="submit"
                >
                  {isSubmitting ? <Loading /> : 'Proceed with your selection'}
                </Button>
              </VStack>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default Cargoform;

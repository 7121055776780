import React, { ReactElement, ReactNode } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
interface customLayout {
  children: ReactNode;
  title?: string;
}
const Layout: React.FC<customLayout> = ({ children, title }) => {
  return (
    <div>
      {/* <Helmet>
        <title>{title}</title>
      </Helmet> */}
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;

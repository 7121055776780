import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';

const GenericModal = ({
  isOpen,
  onClose,
  title,
  children,
  footerContent,
  size,
}: any) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay bg="rgba(0, 0, 0, 0.6)" backdropFilter="blur(8px)" />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        {/* <ModalCloseButton /> */}
        <ModalBody padding={0}>{children}</ModalBody>
        {footerContent && <ModalFooter>{footerContent}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
};

export default GenericModal;

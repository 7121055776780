import React, { useState, useEffect, Suspense, lazy } from 'react';
import axios from 'axios';
import { Box, Button, HStack, Text, Skeleton } from '@chakra-ui/react';
import { PiAirplaneInFlightFill } from 'react-icons/pi';
import { FaFilter } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { GoHomeFill } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

const FlightTable = lazy(() => import('./FlightTable'));

const FlightSearch = () => {
  const filterDataLength = useSelector(
    (state: RootState) => state.filterData.filterData,
  );

  const navigate = useNavigate();
  const [flightData, setFlightData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const handleApi = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await axios.get('https://devbackend.c-energy.co.uk/flights');
      setFlightData(res.data);
    } catch (err) {
      setError('Error fetching data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  let timer: any = null;
  useEffect(() => {
    timer = setTimeout(() => {
      handleApi();
    }, 50000);
    return () => clearTimeout(timer);
  }, []);

  const checkUser = () => {
    navigate('/booking-address');
  };

  const handleBack = () => {
    navigate('/'); // Navigate to the home page
  };

  const tableContent = error ? (
    <Text color="red.500">{error}</Text>
  ) : (
    <Suspense fallback={<Skeleton />}>
      <FlightTable flightData={flightData} checkUser={checkUser} />
    </Suspense>
  );
  const resultText =
    Number(filterDataLength) > 0
      ? `Result ${filterDataLength}`
      : 'No Results Found';
  return (
    <Box
      padding={'4%'}
      w={'full'}
      height={'100vh'}
      backgroundImage={'./booking.png'}
      backgroundRepeat={'no-repeat'}
      backgroundSize={'cover'}
      backgroundPosition={'center center'}
    >
      {/* title */}
      <Box mt={'-40px'}>
        <HStack justify={'center'} spacing={'20px'}>
          <PiAirplaneInFlightFill color="#011C2B" size={59} />
          <Text
            fontWeight={'bold'}
            color={'#011C2B'}
            fontSize={{ base: '2xl', md: '2xl', lg: '4xl' }}
          >
            Flight Search
          </Text>
        </HStack>
        <Text
          fontSize={{ base: '18px', lg: '21px' }}
          textAlign={'center'}
          fontWeight={'medium'}
        >
          Commence the booking process for your products now!
        </Text>
      </Box>

      {/* back button */}
      <Button
        mt="10px"
        px={'45px'}
        border={'1px solid #011C2A'}
        borderRadius={'20px'}
        variant={'outline'}
        onClick={handleBack}
        leftIcon={<GoHomeFill size={20} />}
      >
        Home
      </Button>

      {/* result, filter */}
      <Box display={'flex'} dir="row" gap={'20px'} mt={'10px'}>
        <Button
          px={'50px'}
          border={'1px solid #011C2A'}
          borderRadius={'20px'}
          variant={'outline'}
        >
          {loading ? 'Fetching results...' : resultText}
        </Button>
        <Button
          px={'50px'}
          border={'1px solid #011C2A'}
          borderRadius={'20px'}
          variant={'outline'}
          rightIcon={<FaFilter />}
          onClick={handleApi}
        >
          Filter
        </Button>
      </Box>

      {/* table */}
      <Box
        marginTop={'10px'}
        maxHeight={'calc(100vh - 240px)'}
        overflowY={'auto'}
      >
        {loading ? (
          <>
            {[...Array(8)].map((_, index) => (
              <Skeleton
                key={index}
                height="60px"
                mb="1px"
                startColor="rgba(210, 239, 239, 0.4)"
                endColor="rgba(210, 239, 239, 0.8)"
              />
            ))}
          </>
        ) : (
          tableContent
        )}
      </Box>
    </Box>
  );
};

export default FlightSearch;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FormState {
  departureCode: string;
  arrivalCode: string;
  date: string;
  time: string;
  includedCarrierCodes: string;
}

const initialState: FormState = {
  departureCode: '',
  arrivalCode: '',
  date: '',
  time: '',
  includedCarrierCodes: '',
};

const flightformSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setDepartureData(state, action: PayloadAction<string>) {
      state.departureCode = action.payload;
    },
    setArrivalData(state, action: PayloadAction<string>) {
      state.arrivalCode = action.payload;
    },

    setDate(state, action: PayloadAction<string>) {
      state.date = action.payload;
    },
    setTime(state, action: PayloadAction<string>) {
      state.time = action.payload;
    },
    setCarrierCodes(state, action: PayloadAction<string>) {
      state.includedCarrierCodes = action.payload;
    },
    resetForm(state) {
      state.arrivalCode = '';
      state.departureCode = '';
      state.date = '';
      state.time = '';
      state.includedCarrierCodes = '';
    },
  },
});

export const {
  setDepartureData,
  setArrivalData,
  setDate,
  setTime,
  setCarrierCodes,
  resetForm,
} = flightformSlice.actions;

export default flightformSlice.reducer;

import React from 'react';
import { Input, InputProps, Flex, FormLabel } from '@chakra-ui/react';
import { useField } from 'formik';
interface GenericInputProps extends InputProps {
  name: string;
  label?: string;
}

// Define the generic input component
const GenericInput: React.FC<GenericInputProps> = ({
  label,
  fontSize,
  color,
  fontWeight,
  name,
  ...props
}) => {
  const [field]: any = useField(name);
  return (
    <Flex flexDirection="column" mt="10px" width={{ base: '100%', md: 'auto' }}>
      <FormLabel
        color={color || '#fff'}
        fontSize={fontSize || '14px'}
        fontWeight={fontWeight || 'lighter'}
        marginBottom={'5px'}
      >
        {label}
      </FormLabel>
      <Input {...field} {...props} required />
    </Flex>
  );
};

export default GenericInput;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define a service using a base URL and expected endpoints
export const bookingApi = createApi({
  reducerPath: 'bookingApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://devbackend.c-energy.co.uk/api/' }), // Set the base URL
  endpoints: (builder) => ({
    addBooking: builder.mutation({
      query: (bookingData) => ({
        url: 'bookings', // Endpoint for adding a booking
        method: 'POST',
        body: bookingData, // Request body containing booking data
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const { useAddBookingMutation } = bookingApi;

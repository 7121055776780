import React from 'react';
import {
  Box,
  Image,
  Text,
  VStack,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
} from '@chakra-ui/react';

import Layout from '../../layout/Layout';
import assets from '../../assets';
import ButtonComponent from '../../components/Button';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { listItem, accordItem } from '../../data/how_to_ship';
const HowtoShip = () => {
  return (
    <Layout>
      {/* top-box */}
      <Box>
        {/* How to ship */}
        <Box
          padding={'4%'}
          background={'#F5FAFF'}
          display={'flex'}
          flexDir={{ base: 'column', lg: 'row' }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box
            width={'80%'}
            display={'flex'}
            flexDir={{ base: 'column', lg: 'row' }}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <VStack
              order={{ base: 2, lg: 1 }}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
            >
              <Text
                fontWeight={'bold'}
                color={'#011C2A'}
                fontSize={{ base: '2xl', md: '4xl', lg: '4xl' }}
                marginBottom={'10px'}
              >
                How to Ship
              </Text>
              <Text fontSize={{ sm: '14px', md: '16px' }}>
                Start your seamless shipping journey with Skyshare Cargo by
                completing our straightforward application process to become our
                valued customer.Once the application is completed. You will be
                directed to the login page and can start with your booking.
              </Text>
            </VStack>

            <Image
              order={{ base: 1, lg: 2 }}
              src={assets.how_to_ship}
              alt="image"
            />
          </Box>
        </Box>
        {/* 01 */}
        {listItem.map((item) => (
          <Box
            key={item.id}
            padding={'4%'}
            background={parseInt(item.id, 10) % 2 === 0 ? '#fff' : '#011C2A'}
          >
            <Box
              display={'flex'}
              flexDir={{ base: 'column', lg: 'row' }}
              alignItems={'center'}
            >
              <Box
                width={{ base: '100%', lg: '20%' }}
                order={{ base: 2, lg: 1 }}
              >
                <Text
                  fontSize={{ base: '4xl', lg: '6xl' }}
                  fontWeight={'bold'}
                  color={parseInt(item.id, 10) % 2 === 0 ? '#000' : '#fff'}
                >
                  {item.number}
                </Text>
              </Box>
              <Box
                width={{ base: '100%', lg: '50%' }}
                order={{ base: 3, lg: 2 }}
              >
                <VStack alignItems={'flex-start'} spacing={'10px'}>
                  <Text
                    fontWeight={'bold'}
                    fontSize={{ base: '2xl', lg: '4xl' }}
                    color={parseInt(item.id, 10) % 2 === 0 ? '#000' : '#fff'}
                  >
                    {item.title}
                  </Text>
                  <Text
                    fontWeight={'medium'}
                    fontSize={{ sm: '18px', md: '21px' }}
                    color={parseInt(item.id, 10) % 2 === 0 ? '#000' : '#fff'}
                  >
                    {item.subtitle}
                  </Text>
                  {item.btnExit === true && item.id !== '4' ? (
                    <ButtonComponent
                      width="fit-content"
                      title={item.btntitle}
                      color={parseInt(item.id, 10) % 2 === 0 ? '#fff' : 'black'}
                      backGroundColor={
                        parseInt(item.id, 10) % 2 === 0 ? 'black' : '#fff'
                      }
                    />
                  ) : (
                    item.id === '4' && (
                      <Button
                        width="fit-content"
                        borderRadius="sm"
                        size="md"
                        boxShadow="md"
                        as="a"
                        href="/airwaybill.pdf"
                        download
                        background="#011C2A"
                        color="#fff"
                        target="_blank"
                        _hover={{ background: 'tranparent' }}
                      >
                        Universal Air Waybill
                      </Button>
                    )
                  )}
                </VStack>
              </Box>
              <Box
                width={{ base: '100%', lg: '30%' }}
                order={{ base: 1, lg: 3 }}
                mt={{ base: '10px', lg: '0' }}
              >
                <Image
                  mx={{ base: 'left', lg: 'auto' }}
                  src={item.img}
                  alt={item.title}
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      {/* bottom-box */}
      <Box padding={'4%'}>
        <Text
          fontWeight={'medium'}
          fontSize={{ base: '18px', md: '21px' }}
          textAlign={'center'}
          mb={'20px'}
          color={'gray.600'}
        >
          Want to book your shipment in advance? Apply for an online booking
          account.<span style={{ fontWeight: 'bold' }}>Learn More</span> 
        </Text>

        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'} // Add this line to center-align vertically
          width={'100%'}
          background={'#fff'}
          overflow={'hidden'}
        >
          <VStack
            align="center"
            spacing={8}
            width={{ base: 'md', md: '2xl', lg: '4xl', xl: '5xl' }}
            overflowX={'hidden'}
          >
            {accordItem?.map((item, index) => {
              return (
                <Accordion
                  key={index}
                  allowMultiple
                  width="100%" // Make the accordion span the full width
                  _hover={{ background: 'transparent' }}
                  py={'2%'}
                >
                  <AccordionItem>
                    {({ isExpanded }) => (
                      <>
                        <h2>
                          <AccordionButton
                            py={'2%'}
                            width="100%" // Make the accordion button span the full width
                            bg={'#fff'}
                            _expanded={{ bg: 'tomato', color: 'white' }}
                            _hover={{ bg: 'tomato', color: 'white' }}
                          >
                            <Box
                              as="span"
                              flex="1"
                              textAlign="left"
                              fontSize={{ base: '16px', md: '18px' }}
                              fontWeight={'medium'}
                            >
                              {item.title}
                            </Box>
                            {isExpanded ? (
                              <MinusIcon fontSize="12px" />
                            ) : (
                              <AddIcon fontSize="12px" />
                            )}
                          </AccordionButton>
                        </h2>
                        <AccordionPanel
                          pb={4}
                          background={'transparent'}
                          fontWeight={'normal'}
                        >
                          {item.subtitle}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                </Accordion>
              );
            })}
          </VStack>
        </Box>
      </Box>
    </Layout>
  );
};

export default HowtoShip;

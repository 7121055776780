'use client';
import React, { ReactNode } from 'react';
import { Button } from '@chakra-ui/react';

interface customButtonProp {
  title?: string;
  color?: string;
  width?: string;
  backGroundColor?: string;
  borderRadius?: string;
  variant?: string;
  size?: string;
  type?: any;
  mb?: string;
  isDisabled?: boolean;
  onClickHandler?: () => void;
}

const ButtonComponent: React.FC<customButtonProp> = ({
  title,
  color,
  width,
  backGroundColor,
  borderRadius,
  variant,
  size,
  type,
  mb,
  onClickHandler,
  ...props
}) => {
  return (
    <Button
      {...{
        variant: variant,
        borderRadius: borderRadius || 'sm',
        size: size || 'md',
        boxShadow: 'md',
        width: width || '130px',
        color: color,
        backgroundColor: backGroundColor,
      }}
      _hover={{ backGroundColor: '#011C2B' }}
      onClick={onClickHandler}
      fontSize={{ base: '12px', md: '14px' }}
      type={type}
      {...props}
    >
      {title}
    </Button>
  );
};

export default ButtonComponent;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ShipPage from './(non-authenticated)/dashboard/ship';
import AboutUs from './(non-authenticated)/dashboard/about_us';
import ContactUs from './(non-authenticated)/dashboard/contact_us';
import TrackYourShipment from './(non-authenticated)/dashboard/track_shipment';
import Pagenotfound from './(non-authenticated)/page_not_found';
import LoginPage from './(non-authenticated)/login';
import RegistrationPage from './(non-authenticated)/registration';
import OtpPage from './(non-authenticated)/otp';
import Productpage from './(non-authenticated)/product';
import PaymentPage from './(non-authenticated)/payment';
import HowtoShip from './(non-authenticated)/how_to_ship';
import BookingPage from './(authenticated)/booking/BookingPage';
import { AuthProvider } from './authContext/AuthContext';
import ProtectedRoute from './util/ProtectedRoute';
import MultiStepForm from './(authenticated)/multistep-form/MultiStep-Form';
import BillPayment from './(authenticated)/payment/PaymnetPage';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SuccessPage from './(authenticated)/success-page/PaymentSuceess';
function App() {
  const stripePromise = loadStripe(
    'pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3',
  );

  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<ShipPage />} />
        <Route path="/dashboard/aboutus" element={<AboutUs />} />
        <Route path="/dashboard/contactus" element={<ContactUs />} />
        <Route path="/dashboard/shipment" element={<TrackYourShipment />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<RegistrationPage />} />
        <Route path="/otp" element={<OtpPage />} />
        <Route path="/product" element={<Productpage />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/how_to_ship" element={<HowtoShip />} />
        <Route
          path="/booking"
          element={
            <ProtectedRoute>
              <BookingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/booking-address"
          element={
            <ProtectedRoute>
              <MultiStepForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/billpayment"
          element={
            <ProtectedRoute>
              <Elements stripe={stripePromise}>
                <BillPayment />
              </Elements>
            </ProtectedRoute>
          }
        />
        <Route
          path="/successpage"
          element={
            <ProtectedRoute>
              <SuccessPage />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<Pagenotfound />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;

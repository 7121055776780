// components/CustomInput.tsx
import React from 'react';
import { useField } from 'formik';
import ErrorMessage from './ErrorMessage';
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Button,
  // Add any other Chakra UI components you need
} from '@chakra-ui/react';
import { MdOutlineMail } from 'react-icons/md';
import { GoLock } from 'react-icons/go';
import { IoIosEyeOff, IoMdEye } from 'react-icons/io';
import { FaPhone } from 'react-icons/fa6';

interface CustomInputProps {
  label: string;
  name: string;
  type: string;
  icon?: React.ReactNode;
  showPassword?: boolean;
  isConfirmPassword?: boolean;
  phoneNumber?: boolean;
  togglePassword?: () => void;
}

const CustomInput: React.FC<CustomInputProps> = ({
  label,
  name,
  type,
  showPassword,
  isConfirmPassword,
  phoneNumber,
  togglePassword,
  icon,
}) => {
  const [field, meta]: any = useField(name);

  let inputIcon;
  if (icon) {
    inputIcon = icon;
  } else if (name === 'email') {
    inputIcon = <MdOutlineMail color="gray.300" />;
  } else if (name === 'password' || isConfirmPassword) {
    inputIcon = <GoLock color="gray.300" />;
  } else if (phoneNumber) {
    inputIcon = <FaPhone color="gray.300" />;
  }

  return (
    <FormControl isInvalid={meta.touched && meta.error}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <InputGroup>
        <InputLeftElement>{inputIcon}</InputLeftElement>
        <Input
          {...field}
          id={name}
          type={type}
          placeholder={label}
          required
          pr={name === 'password' || isConfirmPassword ? '3.5rem' : '2.5rem'}
        />
        {(name === 'password' || isConfirmPassword) && (
          <InputRightElement>
            <Button
              h="1.75rem"
              size="sm"
              onClick={togglePassword}
              variant="ghost"
              _hover={{ backgroundColor: 'white' }}
            >
              {showPassword ? (
                <IoIosEyeOff color="#3C505B" fontSize="21px" />
              ) : (
                <IoMdEye color="#3C505B" fontSize="21px" />
              )}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
      {meta.touched && meta.error && (
        <ErrorMessage error={meta.touched && meta.error ? meta.error : null} />
      )}
    </FormControl>
  );
};

export default CustomInput;

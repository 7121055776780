'use client';
import React, { useState } from 'react';
import Layout from '../../../layout/Layout';
import assets from '../../../assets';
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  Stack,
  Text,
  RadioGroup,
  Image,
} from '@chakra-ui/react';

const TrackYourShipment = () => {
  const [value, setValue] = useState('1');
  return (
    <Layout title={'ship'}>
      <Box>
        <Flex direction={{ base: 'column', md: 'row', lg: 'row' }}>
          {/* box with-image */}
          <Box width={{ base: '100%', md: '50%' }} background={'#011C2B'}>
            <Box>
              <Image src={assets.track_shipmnet} alt="track-shipment" />
            </Box>
          </Box>
          {/* box with-input */}
          <Box
            py={{ base: '15%', md: '10%' }}
            px={{ base: '10px', md: '30px' }}
            display={'flex'}
            justifyContent={'center'}
            width={{ base: '100%', md: '50%' }}
            bgGradient={{
              base: 'linear(to-b, #011C2A, #036090)',
              md: 'linear(to-b, #011C2B, #011C2B)',
            }}
          >
            <Box>
              <Text
                fontWeight={'bold'}
                color={'#fff'}
                fontSize={{ base: '2xl', md: '2xl', lg: '4xl' }}
                textAlign={'center'}
                mb={'4%'}
              >
                Track your shipment
              </Text>
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                background={'#fff'}
                width={{ base: '450px', lg: '600px' }}
                className="box-width"
                p={'4%'}
              >
                <Box my={{ base: '0px', md: '20px' }}>
                  <RadioGroup onChange={setValue} value={value}>
                    <Stack direction="row" spacing={5} justify={'center'}>
                      <Radio colorScheme="gray" value="1">
                        Aws Number
                      </Radio>
                      <Radio colorScheme="gray" value="2">
                        Order/Ref Number
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Box>
                <Box my={{ base: '0px', md: '20px' }}>
                  <InputGroup size="lg">
                    <Input borderRadius={'30px'} border={'2px solid #011C2A'} />
                    <InputRightElement
                      width={{ base: '7rem', md: '8rem', lg: '10rem' }}
                    >
                      <Button
                        borderRadius={'30px'}
                        background={'#011C2A'}
                        color={'#fff'}
                        size="lg"
                        width={{ base: '7rem', md: '8rem', lg: '10rem' }}
                      >
                        Track
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </Box>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Layout>
  );
};

export default TrackYourShipment;

import React from 'react';
import { Box, Heading, Text, Divider, VStack, HStack } from '@chakra-ui/react';

const ConfirmPage = ({ values }: any) => {
  return (
    <Box
      p={6}
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
      bg="white"
      maxW="3xl"
      mx="auto"
      py={'10%'}
    >
      <Heading as="h3" size="lg" mb={6} textAlign="center" color="teal.500">
        Confirm Your Details
      </Heading>

      <HStack gap={'40px'}>
        <Box>
          <Text fontWeight="bold" fontSize="lg" color="teal.600" mb={4}>
            Shippers Details:
          </Text>
          <VStack align="start" spacing={2}>
            <Text>Name: {values.pickupName}</Text>
            <Text>Address Line 1: {values.pickupAddressLine1}</Text>
            <Text>Address Line 2: {values.pickupAddressLine2}</Text>
            <Text>City: {values.pickupCity}</Text>
            <Text>State: {values.pickupState}</Text>
            <Text>Postal Code: {values.pickupPostalCode}</Text>
            <Text>Country: {values.pickupCountry}</Text>
            <Text>Contact Number: {values.pickupContactNumber}</Text>
          </VStack>
        </Box>

        <Divider
          orientation="vertical"
          borderColor="teal.300"
          display={{ base: 'none', md: 'block' }}
        />

        <Box>
          <Text fontWeight="bold" fontSize="lg" color="teal.600" mb={4}>
            Consignee Details:
          </Text>
          <VStack align="start" spacing={2}>
            <Text>Name: {values.deliveryName}</Text>
            <Text>Address Line 1: {values.deliveryAddressLine1}</Text>
            <Text>Address Line 2: {values.deliveryAddressLine2}</Text>
            <Text>City: {values.deliveryCity}</Text>
            <Text>State: {values.deliveryState}</Text>
            <Text>Postal Code: {values.deliveryPostalCode}</Text>
            <Text>Country: {values.deliveryCountry}</Text>
            <Text>Contact Number: {values.deliveryContactNumber}</Text>
          </VStack>
        </Box>
      </HStack>
    </Box>
  );
};

export default ConfirmPage;

import React from 'react';
import Layout from '../../../layout/Layout';
import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  VStack,
  Image,
} from '@chakra-ui/react';
import ButtonComponent from '../../../components/Button';
import assets from '../../../assets';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import SearchInput from './flight_scheduling/SearchInput';

const ShipPage = () => {
  return (
    <Layout>
      {/* Hero-section */}
      <Box background={'#F5FAFF'} py={'10px'} overflow={'hidden'}>
        <Flex direction={{ base: 'column', md: 'row' }}>
          {/* left-box */}
          <Box my={'20px'} width={{ base: 'full', md: '60%' }} px={'40px'}>
            <VStack
              spacing={'15px'}
              align={'flex-start'}
              marginTop={{ base: '0%', md: '-6%', lg: '10%' }}
              className="textContent"
            >
              <Text
                fontWeight={'bold'}
                color={'#011C2B'}
                fontSize={{ base: '2xl', md: '2xl', lg: '4xl' }}
                className="textContent"
              >
                Aircraft Cargo Right Sizing for Low Cost & Reduced Emissions​
              </Text>
              <Text
                maxW={{ sm: '85%', md: '100%', lg: '85%%' }}
                fontSize={{ base: '14px', md: '16px', lg: '18px' }}
              >
                Skyshare is a PaaS product looking to integrate various stages
                and mechanisms of cargo transportation in the airline industry
                using a platform-based aggregation approach by
                integrating capacity available and demand. Over a period of
                time, the data collated will facilitate targeted BTL
                marketing outreach​.
              </Text>
              <ButtonComponent
                backGroundColor="#011C2B"
                color="#fff"
                title="Learn More"
                borderRadius="4px"
              />
            </VStack>
          </Box>
          {/* right-box */}
          <Box width={{ base: 'full', md: '40%' }} position="relative">
            <Box zIndex={-1} width={'70%'} ml={'auto'}>
              <Image src={assets.ellipse} alt="background-image" />
            </Box>
            <Box
              zIndex={1}
              position="absolute"
              top="40%"
              left="50%"
              transform="translate(-50%, -50%)"
              width={'140%'}
            >
              <Image
                style={{ marginLeft: '10%' }}
                src={assets.airolane}
                alt="background-image"
              />
            </Box>
          </Box>
        </Flex>
      </Box>

      {/* flight-schedule */}
      <Box
        backgroundColor={'#011C2B'}
        zIndex={1}
        mt={{ base: '-10%', md: '-5%' }}
      >
        <Flex
          direction={{ base: 'column', md: 'row' }}
          alignItems="center"
          justifyContent="center"
        >
          {/* left-box */}
          <Box
            width={{ base: '100%', md: '40%' }}
            paddingY={0}
            marginY={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box width={'100%'} height={'10%'}>
              <Image src={assets.f_image} alt="background-image" />
            </Box>
          </Box>
          {/* box-right */}

          <SearchInput />
        </Flex>
      </Box>

      {/* news-cargo-facility */}
      <Box>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          zIndex={'-1'}
          gap={{ base: '60px' }}
        >
          {/* left-box */}
          <Box
            position="relative"
            width={{ base: '100%', md: '50%' }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            bg={'#F5FAFF'}
            paddingTop={'5%'}
          >
            <Image src={assets.firstImage} alt="background-image" />

            {/* Absolute positioned box for text */}
            <Box
              position="absolute"
              // top={30}
              left={0}
              zIndex={1}
              textAlign={'left'}
              width="100%"
            >
              <VStack justifyItems={'flex-start'} spacing={'20px'}>
                <Text
                  fontWeight={'bold'}
                  color={'#011C2B'}
                  fontSize={{ base: '2xl', md: '2xl', lg: '4xl' }}
                >
                  Cargo facilities
                </Text>
                <Text fontSize={{ base: '14px', md: '16px', lg: '24px' }}>
                  Find your nearest shipping location
                </Text>
                <Button
                  fontSize={{ base: '14px', md: '16px', lg: '24px' }}
                  rightIcon={<ArrowForwardIcon mt={'5px'} fontSize={'27px'} />}
                  colorScheme="teal"
                  variant="ghost"
                >
                  Find a location
                </Button>
              </VStack>
            </Box>
          </Box>
          {/* right-box */}
          <Box
            width={{ base: '100%', md: '50%' }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            position="relative"
          >
            <Image src={assets.secondImage} alt="background-image" />
            <Box
              position="absolute"
              // top={30}
              left={0}
              zIndex={1}
              textAlign={'left'}
              width="100%"
            >
              <VStack justifyItems={'flex-start'} spacing={'20px'}>
                <Text
                  fontWeight={'bold'}
                  color={'#011C2B'}
                  fontSize={{ base: '2xl', md: '2xl', lg: '4xl' }}
                >
                  News
                </Text>
                <Text fontSize={{ base: '14px', md: '16px', lg: '24px' }}>
                  Find your nearest shipping location
                </Text>
                <Text fontSize={{ base: '14px', md: '16px', lg: '24px' }}>
                  Skyshare® Cargo Celebrates Womens History Month!
                </Text>
                <Text fontSize={{ base: '14px', md: '16px', lg: '24px' }}>
                  Customer Spotlight: Hilverda De Boer!
                </Text>
                <Text fontSize={{ base: '14px', md: '16px', lg: '24px' }}>
                  Skyshare Cargo is heading back to Hawaii!
                </Text>
              </VStack>
            </Box>
          </Box>
        </Flex>
      </Box>
      {/* sky-share cargo
       */}
      <Box bgGradient="linear(to-b,#4D606B, #011C2B)" py={'50px'}>
        <Flex direction={{ base: 'column', md: 'row' }}>
          {/* left-box */}
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            width={{ base: '100%', md: '50%' }}
          >
            <VStack>
              <HStack>
                <Image src={assets.logo1} alt="background-image" />
                <Text
                  fontWeight={'bold'}
                  color={'#fff'}
                  fontSize={{ base: '2xl', md: '2xl', lg: '4xl' }}
                >
                  New To Skyshare Cargo ?
                </Text>
              </HStack>
              <Text
                color={'#fff'}
                fontSize={{ base: '14px', md: '16px', lg: '24px' }}
                ml={{ base: '25%', lg: '18%' }}
              >
                Getting started is easy with our new how to guide
              </Text>
              <Box ml={{ base: '-15%', lg: '-40%' }} mt={'6%'}>
                <Link to="how_to_ship">
                  <ButtonComponent title="Learn more" />
                </Link>
              </Box>
            </VStack>
          </Box>
          {/* right-box */}
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            width={{ base: '100%', md: '50%' }}
            marginTop={{ base: '50px' }}
          >
            <VStack>
              <HStack>
                <Box mt={{ md: '-25%' }}>
                  <Image src={assets.logo2} alt="background-image" />
                </Box>

                <Text
                  fontWeight={'bold'}
                  color={'#fff'}
                  fontSize={{ base: '2xl', md: '2xl', lg: '4xl' }}
                  mt={{ base: '10%', md: '-15%' }}
                >
                  Ready To <br />
                  Open Skyshare Account ?
                </Text>
              </HStack>
              <Text
                color={'#fff'}
                fontSize={{ base: '14px', md: '16px', lg: '24px' }}
                ml={{ base: '-8%', md: '-3%', lg: '-10%' }}
              >
                Apply for an account today
              </Text>
              <Box ml={{ base: '-18%', md: '-20%', lg: '-40%' }} mt={'6%'}>
                <Link to={'/signup'}>
                  <ButtonComponent title="Apply now" />
                </Link>
              </Box>
            </VStack>
          </Box>
        </Flex>
      </Box>
    </Layout>
  );
};

export default ShipPage;

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

interface LocationState {
  latitude: number | null;
  longitude: number | null;
  address: { [key: string]: string } | null;
  error: string | null;
}

// Async thunk to fetch the address
export const fetchAddress = createAsyncThunk(
  'location/fetchAddress',
  async ({ latitude, longitude }: { latitude: number; longitude: number }) => {
    const response = await fetch(
      `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=14230fe974a64bbea20d5f966f9d787d`,
    );
    const data = await response.json();
    if (data.results.length > 0) {
      return data.results[0].components;
    } else {
      throw new Error('Unable to retrieve address.');
    }
  },
);

// Initial state
const initialState: LocationState = {
  latitude: null,
  longitude: null,
  address: null,
  error: null,
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocation: (
      state,
      action: PayloadAction<{ latitude: number; longitude: number }>,
    ) => {
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddress.fulfilled, (state, action) => {
        state.address = action.payload;
      })
      .addCase(fetchAddress.rejected, (state, action) => {
        state.error = action.error.message || 'Error fetching address.';
      });
  },
});

export const { setLocation, setError } = locationSlice.actions;

export default locationSlice.reducer;

import React, { useState } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {
  Button,
  FormControl,
  Box,
  Text,
  VStack,
  HStack,
  Divider,
} from '@chakra-ui/react';
import { FaCreditCard } from 'react-icons/fa';
import { BsArrowLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const BillPayment = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const sendMessage = async () => {
    const to = '+917009026271';
    const body = 'Payment Successful';

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ to: to, body: body }),
    };

    try {
      const response = await fetch(
        'https://devbackend.c-energy.co.uk/api/send-sms',
        requestOptions,
      );
      if (!response.ok) {
        throw new Error('Failed to send SMS');
      }
      console.log('SMS sent successfully');
      alert('SMS sent successfully');
    } catch (error: any) {
      console.error('Error sending SMS:', error.message);
    }
  };

  const sendEmail = async () => {
    const to = 'shekhar.devanshee@gmail.com';
    const from = 'zevin022019@gmail.com';
    const subject = 'Payment Confirmation';
    const text = 'Payment Successful';

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: to,
        from: from,
        subject: subject,
        text: text,
      }),
    };

    try {
      const response = await fetch(
        'https://devbackend.c-energy.co.uk/api/send-email',
        requestOptions,
      );
      if (!response.ok) {
        throw new Error('Failed to send Email');
      }
      console.log('Email sent successfully');
      alert('Email sent successfully');
    } catch (error: any) {
      console.error('Error sending Email:', error.message);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    const { error: stripeError, paymentMethod } =
      await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement!,
      });

    if (stripeError) {
      setError(stripeError.message || 'An unknown error occurred.');
      setSuccess(null);
    } else {
      setSuccess('Payment successful!');
      setError(null);
      console.log('PaymentMethod:', paymentMethod);

      // Call SMS and Email functions
      sendMessage();
      sendEmail();

      navigate('/successpage');
    }
  };

  const handleBtn = () => {
    navigate('/booking');
  };

  return (
    <Box
      backgroundImage={'./Designer.jpg'}
      backgroundSize={'cover'}
      backgroundPosition={'center center'}
      width={'100%'}
      height={'100vh'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Box
        background={'rgba(155, 53, 180, 0.4)'}
        opacity={8}
        p={'2%'}
        width={{ sm: '80%', md: '60%', lg: '45%' }}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        borderRadius={'md'}
        boxShadow={'md'}
        border={'0.5px solid #fff'}
      >
        <Text fontSize={'2xl'} mb={'10px'} fontWeight={'bold'} color={'#fff'}>
          Proceed with the Payment
        </Text>
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={{ sm: 'column', lg: 'row' }}
        >
          <Box
            width={{ sm: '100%', lg: '40%' }}
            background={'gray.100'}
            paddingY={'10px'}
            paddingX={'20px'}
          >
            <Text fontSize={'xl'} fontWeight={'regular'} my={4}>
              Order Summary
            </Text>
            <VStack alignItems={'flex-start'} gap={'30px'}>
              <HStack>
                <Text
                  color={'gray.500'}
                  fontSize={'14px'}
                  textTransform={'uppercase'}
                  width={'150px'}
                >
                  order subtotal
                </Text>
                <Text
                  fontSize={'16px'}
                  fontWeight={'bold'}
                  textTransform={'uppercase'}
                >
                  $--
                </Text>
              </HStack>
              <HStack>
                <Text
                  color={'gray.500'}
                  fontSize={'14px'}
                  textTransform={'uppercase'}
                  width={'150px'}
                >
                  shipping and handling
                </Text>
                <Text
                  fontSize={'16px'}
                  fontWeight={'bold'}
                  textTransform={'uppercase'}
                >
                  $--
                </Text>
              </HStack>
              <HStack>
                <Text
                  color={'gray.500'}
                  fontSize={'14px'}
                  textTransform={'uppercase'}
                  width={'150px'}
                >
                  sales tax
                </Text>
                <Text
                  fontSize={'16px'}
                  fontWeight={'bold'}
                  textTransform={'uppercase'}
                >
                  $--
                </Text>
              </HStack>
              <Divider borderColor="gray.300" />
              <HStack>
                <Text
                  color={'gray.500'}
                  fontSize={'14px'}
                  textTransform={'uppercase'}
                  width={'150px'}
                >
                  payment due
                </Text>
                <Text
                  fontSize={'16px'}
                  fontWeight={'bold'}
                  textTransform={'uppercase'}
                  color={'#9B35B4'}
                >
                  $--
                </Text>
              </HStack>
            </VStack>
            <HStack mt={'10%'} onClick={handleBtn} cursor={'pointer'}>
              <BsArrowLeft size={'27px'} color="#9B35B4" />
              <Text color="#9B35B4" fontSize={'14px'}>
                Go Back
              </Text>
            </HStack>
          </Box>
          <Box
            width={{ sm: '100%', lg: '60%' }}
            boxShadow={'md'}
            padding={'20px'}
            backgroundColor={'#fff'}
            shadow={'md'}
          >
            <Text fontSize={'xl'} fontWeight={'regular'} mb={4}>
              Enter Card Details
            </Text>

            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="stretch">
                {/* Card Number */}
                <Text fontSize="sm" mr={'auto'} mb={-3} fontWeight={'bold'}>
                  Card Number
                </Text>
                <FormControl
                  border={'1px solid teal'}
                  padding={'10px'}
                  borderRadius={'5px'}
                  boxShadow={'md'}
                  position={'relative'}
                >
                  <Box
                    position={'absolute'}
                    right={'10px'}
                    top={'50%'}
                    transform={'translateY(-50%)'}
                  >
                    <FaCreditCard color="#9B35B4" />
                  </Box>
                  <CardNumberElement
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#424770',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                </FormControl>

                {/* Expiry Date */}
                <Text fontSize="sm" mr={'auto'} mb={-3} fontWeight={'bold'}>
                  Expiry Date
                </Text>
                <FormControl
                  border={'1px solid teal'}
                  padding={'10px'}
                  borderRadius={'5px'}
                  boxShadow={'md'}
                >
                  <CardExpiryElement
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#424770',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                </FormControl>

                {/* CVV */}
                <Text fontSize="sm" mr={'auto'} mb={-3} fontWeight={'bold'}>
                  CVV
                </Text>
                <FormControl
                  border={'1px solid teal'}
                  padding={'10px'}
                  borderRadius={'5px'}
                  boxShadow={'md'}
                >
                  <CardCvcElement
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#424770',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                </FormControl>

                <Button
                  color={'#fff'}
                  type="submit"
                  disabled={!stripe}
                  background={'#9B35B4'}
                >
                  Pay
                </Button>

                {error && <Text color="red">{error}</Text>}
                {success && <Text color="green">{success}</Text>}
              </VStack>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BillPayment;

'use client';
import React, { useState } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Text,
  VStack,
  Image,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { BsGoogle } from 'react-icons/bs';
import { FiGithub } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import CustomInput from '../../components/CustomInput';
import { CiUser } from 'react-icons/ci';
import * as yup from 'yup';
import { useToast } from '@chakra-ui/react';
import { useRegisterUserMutation } from '../../store/slices/registrationApiSlice';

interface CustomInputProps {
  label?: string;
  name?: string;
  type?: string;
  showPassword?: boolean;
  togglePassword?: () => void;
}

const validationSchema = yup.object({
  username: yup.string().required('Please enter a username'),
  email: yup.string().email().required('Please enter your email'),
  phone_number: yup
    .string()
    .required('Please enter your phone number')
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, 'Phone number is not valid'),
  password: yup
    .string()
    .required('Please enter your password')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Min 8 characters, mixed case, P@word12',
    ),
  confirm_password: yup
    .string()
    .required('Please confirm your password')
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

const RegistrationPage: React.FC<CustomInputProps> = () => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [showPassword, setShowPassword] = useState(false);
  const toast = useToast();
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const [registerUser] = useRegisterUserMutation();

  return (
    <Flex direction={{ base: 'column', md: 'row' }} h={'100vh'}>
      {/* login left-box */}
      <Box
        backgroundImage={'/login-img.png'}
        backgroundSize={'cover'}
        backgroundPosition={'center center'}
        width={{ base: '100%', md: '50%' }}
        h={'100vh'}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minH={'500px'}
      >
        <VStack>
          <Link to={'/'}>
            <Text
              textAlign={'center'}
              fontWeight={'bold'}
              color={'#fff'}
              fontSize={{ base: '3xl', md: '5xl' }}
            >
              Skyshare Cargo
            </Text>
            <Text
              textAlign={'center'}
              fontWeight={'md'}
              color={'#fff'}
              fontSize={{ base: 'xl', md: '2xl' }}
            >
              Aircraft Cargo Right Sizing For Low Cost & reduced Emission
            </Text>
          </Link>
        </VStack>
      </Box>
      {/* login right-box */}
      <Formik
        initialValues={{
          username: '',
          email: '',
          phone_number: '',
          password: '',
          confirm_password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            const { data } = await registerUser(values).unwrap();
            console.log('Registration successful:', data?.message);
            resetForm();
            toast({
              title: 'Registration successful!',
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
          } catch (error: any) {
            toast({
              title: 'Registration failed',
              description: error.data.message || 'Unable to register.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
            console.error('Registration failed:', error);
          }
        }}
      >
        <Box
          width={{ base: '100%', md: '50%' }}
          h={'100vh'}
          overflowY={{ base: 'visible', md: 'auto' }}
          paddingInline={{ base: '4%', md: '0%' }}
        >
          <VStack>
            <Box ml="auto" mt={'4%'}>
              <Image src="/flogo.svg" width={{ xs: 200, sm: 300 }} alt="logo" />
            </Box>
            <Box marginTop={'-30px'}>
              <Text
                textAlign={'center'}
                fontWeight={'bold'}
                color={'#3C505B'}
                fontSize={{ base: '3xl', md: '5xl' }}
              >
                Welcome
              </Text>
              <Text
                textAlign={'center'}
                fontWeight={'md'}
                color={'gray.500'}
                fontSize={{ base: 'md', md: 'xl' }}
              >
                Register your account
              </Text>
            </Box>
          </VStack>
          <Center>
            <Form>
              <VStack spacing={3} my={'10px'}>
                {/* username */}
                <CustomInput
                  label="Username"
                  name="username"
                  type="text"
                  icon={<CiUser color="gray.300" />}
                />
                {/* email */}
                <CustomInput label="Email" name="email" type="email" />
                {/* phone number */}
                <CustomInput
                  label="Phone Number"
                  name="phone_number"
                  type="text"
                  phoneNumber={true}
                />
                {/* password */}
                <CustomInput
                  label="Password"
                  name="password"
                  type={show ? 'text' : 'password'}
                  showPassword={!!show}
                  togglePassword={handleClick}
                />
                {/* Confirm Password */}
                <CustomInput
                  label="Confirm Password"
                  name="confirm_password"
                  type={showPassword ? 'text' : 'password'}
                  showPassword={!!showPassword}
                  togglePassword={togglePassword}
                  isConfirmPassword
                />
                {/* Signup_btn */}
                <Button
                  color={'#fff'}
                  width={{ base: 'xs', md: 'md' }}
                  backgroundColor={'#3C505B'}
                  borderRadius="50px"
                  _hover={{ backgroundColor: 'black' }}
                  type={'submit'}
                >
                  Signup
                </Button>
                <Text color="gray.400">or</Text>

                {/* social sign-in sso */}
                <HStack>
                  <Button
                    width={{ sm: '100px', md: '200px' }}
                    variant={'outline'}
                    borderColor={'#3C505B'}
                    borderRadius="50px"
                    leftIcon={<BsGoogle />}
                  >
                    Google
                  </Button>
                  <Button
                    width={{ sm: '100px', md: '200px' }}
                    variant={'outline'}
                    borderColor={'#3C505B'}
                    borderRadius="50px"
                    leftIcon={<FiGithub />}
                  >
                    GitLab
                  </Button>
                </HStack>
                <Text color="#3C505B">
                  Already Registered?
                  <Link to={'/login'}>
                    <span style={{ color: 'black', fontWeight: 'bold' }}>
                      Login
                    </span>
                  </Link>{' '}
                </Text>
              </VStack>
            </Form>
          </Center>
        </Box>
      </Formik>
    </Flex>
  );
};

export default RegistrationPage;

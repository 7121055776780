'use client';
import React from 'react';
import {
  Box,
  Flex,
  HStack,
  Text,
  VStack,
  Textarea,
  Radio,
  RadioGroup,
  Stack,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Image,
} from '@chakra-ui/react';
import Layout from '../../../layout/Layout';
import { BiSolidPhoneCall } from 'react-icons/bi';
import { MdEmail } from 'react-icons/md';
import { FaLocationDot } from 'react-icons/fa6';
import { Form, Formik } from 'formik';
import {
  FaTwitterSquare,
  FaInstagramSquare,
  FaFacebookSquare,
} from 'react-icons/fa';
import assets from '../../../assets';
import { Link } from 'react-router-dom';

import GenericInput from '../../../components/GenericInput';
import ButtonComponent from '../../../components/Button';
import { MinusIcon, AddIcon } from '@chakra-ui/icons';
import { accordItem } from '../../../data/contact_us';
interface CustomInputProps {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  message?: string;
  type?: string;
}

const ContactUs: React.FC<CustomInputProps> = () => {
  return (
    <Layout>
      {/* header */}
      <Box textAlign={'center'} mb={'2%'}>
        <Text
          fontWeight={'bold'}
          color={'#011C2B'}
          fontSize={{ base: '2xl', md: '2xl', lg: '4xl' }}
          my="10px"
        >
          Contact Us
        </Text>
        <Text
          color={'gray.600'}
          fontSize={{ base: '14px', md: '16px', lg: '18px' }}
        >
          Any question or remarks? Just write us a message!
        </Text>
      </Box>
      {/* contact-form */}
      <Flex direction={{ base: 'column', md: 'row' }} overflow={'hidden'}>
        {/* left-contact-inforamtion */}
        <Box
          position={'relative'}
          display={'flex'}
          justifyContent={{ base: 'center', md: 'flex-start' }}
          alignItems={{ base: 'center', md: 'flex-start' }}
          color={'#fff'}
          width={{ base: '100%', md: '40%' }}
          background={'#011C2B'}
          py="5%"
          px={{ base: '0%', md: '5%' }}
        >
          <VStack
            justifyContent={{ base: 'center', md: 'flex-start' }}
            alignItems={{ base: 'center', md: 'flex-start' }}
          >
            <Box>
              <Text fontSize={{ base: '21px', lg: '24px' }} fontWeight={'bold'}>
                Contact Information
              </Text>
              <Text fontSize={{ base: '16px', md: '18px' }} fontWeight="light">
                Connect with us for any help!
              </Text>
            </Box>

            <Box pl={{ base: '30%', md: '0%' }}>
              <HStack spacing={'25px'} my={'15%'}>
                <BiSolidPhoneCall size={'27px'} />
                <Text>XX-XXXX-XX</Text>
              </HStack>
              <HStack spacing={'25px'} my={'15%'}>
                <MdEmail size={'27px'} />
                <Text>info@c-energy.co.uk</Text>
              </HStack>
              <HStack spacing={'25px'} my={'15%'}>
                <FaLocationDot size={'27px'} />
                <Text width={{ sm: '150px', md: 'full' }}>
                Cenergy Limited 54 Berberis Walk UB77TZ COMPANIES HOUSE REGN : 12671205
                </Text>
              </HStack>
            </Box>
            <Box>
              <HStack spacing={'40px'}>
                <Link to={'#'}>
                  <FaTwitterSquare size={'24px'} />
                </Link>
                <Link to={'#'}>
                  <FaInstagramSquare size={'24px'} />
                </Link>
                <Link to={'#'}>
                  <FaFacebookSquare size={'24px'} />
                </Link>
              </HStack>
            </Box>
          </VStack>
          <Box
            display={'flex'}
            alignItems="baseline"
            justifyContent="flex-end"
            position={'absolute'}
            right={-15}
            bottom={'-125px'}
            zIndex={10}
          >
            <Image src={assets.circle} alt={'ellipse'} />
          </Box>
        </Box>
        {/* right-input-filed*/}

        <Box
          display="flex"
          width={{ base: '100%', md: '60%' }}
          justifyContent={'center'}
        >
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              message: '',
              selectquery: '',
            }}
            onSubmit={(values, { resetForm }) => {
              console.log(values);
              resetForm();
            }}
          >
            {/* Input Fields */}
            {({ values, handleChange }) => (
              <Form>
                <VStack spacing={'50px'}>
                  <Box
                    display={'flex'}
                    flexDir={{ base: 'column', xl: 'row' }}
                    gap="10px"
                  >
                    <GenericInput
                      color={'gray.800'}
                      fontSize={'18px'}
                      fontWeight={'normal'}
                      label="First name"
                      placeholder="first name"
                      width={{ base: 'md', xl: 'sm' }}
                      border={'1px solid #3C505B'}
                      type="text"
                      name="firstName"
                    />

                    <GenericInput
                      color={'gray.800'}
                      fontSize={'18px'}
                      fontWeight={'normal'}
                      label="Last name"
                      placeholder="last name"
                      width={{ base: 'md', xl: 'sm' }}
                      border={'1px solid #3C505B'}
                      type="text"
                      name="lastName"
                    />
                  </Box>
                  <Box
                    display={'flex'}
                    flexDir={{ base: 'column', xl: 'row' }}
                    gap="10px"
                  >
                    <GenericInput
                      color={'gray.800'}
                      fontSize={'18px'}
                      fontWeight={'normal'}
                      label="Email"
                      placeholder="email"
                      width={{ base: 'md', xl: 'sm' }}
                      border={'1px solid #3C505B'}
                      type="email"
                      name="email"
                    />
                    <GenericInput
                      color={'gray.800'}
                      fontSize={'18px'}
                      fontWeight={'normal'}
                      label="number"
                      placeholder="Phone number"
                      width={{ base: 'md', xl: 'sm' }}
                      border={'1px solid #3C505B'}
                      type="phone"
                      name="phone"
                    />
                  </Box>

                  <Box>
                    <Text
                      mb="8px"
                      color={'gray.800'}
                      fontSize={'18px'}
                      fontWeight={'normal'}
                    >
                      {'Message'}
                    </Text>
                    <Textarea
                      border={'1px solid #3C505B'}
                      placeholder="Enter your message"
                      value={values.message}
                      onChange={handleChange}
                      width={{ base: 'md', xl: '3xl' }}
                      name="message"
                    />
                  </Box>

                  <Box mx={'auto'} width={'100%'}>
                    <Text
                      mb="8px"
                      color={'gray.800'}
                      fontSize={'18px'}
                      fontWeight={'normal'}
                    >
                      {'Message'}
                    </Text>
                    <RadioGroup
                      value={values.selectquery}
                      onChange={(value) =>
                        handleChange({
                          target: { name: 'selectquery', value },
                        })
                      }
                    >
                      <Stack direction="row" spacing={'20px'}>
                        <Radio border={'1px solid #3C505B'} value={'1'}>
                          General Enquiry
                        </Radio>
                        <Radio border={'1px solid #3C505B'} value={'2'}>
                          General Enquiry
                        </Radio>
                        <Radio border={'1px solid #3C505B'} value={'3'}>
                          General Enquiry
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Box>
                  <Box mx={'auto'} width={'100%'} mb="2%">
                    <ButtonComponent
                      color="#fff"
                      backGroundColor="#011C2A"
                      title="submit"
                      type={'submit'}
                      width="200px"
                    />
                  </Box>
                </VStack>
              </Form>
            )}
          </Formik>
        </Box>
      </Flex>

      {/* according */}
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'} // Add this line to center-align vertically
        width={'100%'}
        background={'#3C505B'}
        py={'2%'}
        overflow={'hidden'}
        px={'0%'}
        zIndex={0}
      >
        <VStack
          align="center"
          spacing={8}
          width={{ base: 'md', md: '2xl', lg: '4xl', xl: '5xl' }}
          overflowX={'hidden'}
        >
          <Text
            fontWeight={'bold'}
            color={'#fff'}
            fontSize={{ base: '2xl', md: '2xl', lg: '4xl' }}
            my="10px"
            textAlign={'center'}
          >
            Frequently Asked Questions
          </Text>
          {accordItem?.map((item, index) => {
            return (
              <Accordion
                key={index}
                allowMultiple
                width="100%" // Make the accordion span the full width
                _hover={{ background: 'transparent' }}
                py={'5%'}
                mt={'-5%'}
              >
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <h2>
                        <AccordionButton
                          py={'2%'}
                          width="100%" // Make the accordion button span the full width
                          bg={'#fff'}
                          _expanded={{ bg: 'tomato', color: 'white' }}
                          _hover={{ bg: 'tomato', color: 'white' }}
                        >
                          <Box
                            as="span"
                            flex="1"
                            textAlign="left"
                            fontSize={'18px'}
                            fontWeight={'medium'}
                          >
                            {item.title}
                          </Box>
                          {isExpanded ? (
                            <MinusIcon fontSize="12px" />
                          ) : (
                            <AddIcon fontSize="12px" />
                          )}
                        </AccordionButton>
                      </h2>
                      <AccordionPanel
                        pb={4}
                        background={'transparent'}
                        color={'#fff'}
                        fontWeight={'normal'}
                      >
                        {item.subtitle}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            );
          })}
        </VStack>
      </Box>
    </Layout>
  );
};

export default ContactUs;

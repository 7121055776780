import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface filterData {
  filterData: number | string;
}

const initialState: filterData = {
  filterData: 0,
};

const filterDataSlice = createSlice({
  name: 'filterData',
  initialState,
  reducers: {
    setFilterData: (state, action: PayloadAction<number | string>) => {
      state.filterData = action.payload;
    },
  },
});

export const { setFilterData } = filterDataSlice.actions;
export default filterDataSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ShipmentDetails {
  product: string;
  cargo_height: string | number;
  cargo_length: string | number;
  cargo_width: string | number;
  cargo_weight: string | number;
  cargo_volume: string | number;
  cargo_description: string;
}

interface SenderDetails {
  name: string;
  email: string;
  address: string;
  contact: string;
  city: string;
  pincode: string;
  state: string;
  country: string;
}

interface ConsigneeDetails {
  name: string;
  email: string;
  address: string;
  contact: string;
  city: string;
  pincode: string;
  state: string;
  country: string;
}

interface WaybillState {
  shipment: ShipmentDetails;
  sender: SenderDetails;
  consignee: ConsigneeDetails;
}

const initialState: WaybillState = {
  shipment: {
    product: '',
    cargo_height: '',
    cargo_length: '',
    cargo_width: '',
    cargo_weight: '',
    cargo_volume: '',
    cargo_description: '',
  },
  sender: {
    name: '',
    email: '',
    address: '',
    contact: '',
    city: '',
    pincode: '',
    state: '',
    country: '',
  },
  consignee: {
    name: '',
    email: '',
    address: '',
    contact: '',
    city: '',
    pincode: '',
    state: '',
    country: '',
  },
};

const waybillSlice = createSlice({
  name: 'waybill',
  initialState,
  reducers: {
    setProduct(state, action: PayloadAction<string>) {
      state.shipment.product = action.payload;
    },
    setCargoHeight(state, action: PayloadAction<string | number>) {
      state.shipment.cargo_height = action.payload;
    },
    setCargoLength(state, action: PayloadAction<string | number>) {
      state.shipment.cargo_length = action.payload;
    },
    setCargoWidth(state, action: PayloadAction<string | number>) {
      state.shipment.cargo_width = action.payload;
    },
    setCargoWeight(state, action: PayloadAction<string | number>) {
      state.shipment.cargo_weight = action.payload;
    },
    setCargoVolume(state, action: PayloadAction<string | number>) {
      state.shipment.cargo_volume = action.payload;
    },
    setCargoDescription(state, action: PayloadAction<string>) {
      state.shipment.cargo_description = action.payload;
    },
    setSenderName(state, action: PayloadAction<string>) {
      state.sender.name = action.payload;
    },
    setSenderEmail(state, action: PayloadAction<string>) {
      state.sender.email = action.payload;
    },
    setSenderAddress(state, action: PayloadAction<string>) {
      state.sender.address = action.payload;
    },
    setSenderContact(state, action: PayloadAction<string>) {
      state.sender.contact = action.payload;
    },
    setSenderCity(state, action: PayloadAction<string>) {
      state.sender.city = action.payload;
    },
    setSenderPincode(state, action: PayloadAction<string>) {
      state.sender.pincode = action.payload;
    },
    setSenderState(state, action: PayloadAction<string>) {
      state.sender.state = action.payload;
    },
    setSenderCountry(state, action: PayloadAction<string>) {
      state.sender.country = action.payload;
    },
    setConsigneeName(state, action: PayloadAction<string>) {
      state.consignee.name = action.payload;
    },
    setConsigneeEmail(state, action: PayloadAction<string>) {
      state.consignee.email = action.payload;
    },
    setConsigneeAddress(state, action: PayloadAction<string>) {
      state.consignee.address = action.payload;
    },
    setConsigneeContact(state, action: PayloadAction<string>) {
      state.consignee.contact = action.payload;
    },
    setConsigneeCity(state, action: PayloadAction<string>) {
      state.consignee.city = action.payload;
    },
    setConsigneePincode(state, action: PayloadAction<string>) {
      state.consignee.pincode = action.payload;
    },
    setConsigneeState(state, action: PayloadAction<string>) {
      state.consignee.state = action.payload;
    },
    setConsigneeCountry(state, action: PayloadAction<string>) {
      state.consignee.country = action.payload;
    },
  },
});

export const {
  setProduct,
  setCargoHeight,
  setCargoLength,
  setCargoWidth,
  setCargoWeight,
  setCargoVolume,
  setCargoDescription,
  setSenderName,
  setSenderEmail,
  setSenderAddress,
  setSenderContact,
  setSenderCity,
  setSenderPincode,
  setSenderState,
  setSenderCountry,
  setConsigneeName,
  setConsigneeEmail,
  setConsigneeAddress,
  setConsigneeContact,
  setConsigneeCity,
  setConsigneePincode,
  setConsigneeState,
  setConsigneeCountry,
} = waybillSlice.actions;

export default waybillSlice.reducer;

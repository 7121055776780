import React, { useEffect } from 'react';
import { Fragment, useState } from 'react';
import { Image, useToast } from '@chakra-ui/react';
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { logout, isAuthenticated } from '../util/AuthUtils';

function classNames(...classes: string[]): any {
  return classes.filter(Boolean).join(' ');
}
const products = [
  { name: 'Product', to: '/product', icon: ChartPieIcon },
  { name: 'Charges and payment', to: '/payment', icon: CursorArrowRaysIcon },
  { name: 'How to ship', to: '/how_to_ship', icon: QuestionMarkCircleIcon },
];
const Header = () => {
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated());
  const navigate = useNavigate();
  const toast = useToast();

  const handleLogout = () => {
    logout();
    setIsLoggedIn(false);
    toast({
      title: 'User logged out successfully',
      status: 'success',
      duration: 3000,
      isClosable: true,
      position: 'top',
    });
    navigate('/');
  };
  useEffect(() => {
    if (isLoggedIn) {
      const timer = setTimeout(() => {
        handleLogout();
      }, 300000);
  
      return () => clearTimeout(timer);
    }
    setIsLoggedIn(isAuthenticated());
  }, [isLoggedIn]);

  
  return (
    <header style={{ background: '#F5FAFF' }}>
      <nav
        className="mx-auto flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1 ">
          <Link to="/">
            <Image
              width={{ sm: 200, md: 220 }}
              src="/brandlogo.svg"
              alt="logo"
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <Popover className="relative">
            <Popover.Button
              className={`link ${
                location.pathname === '/'
                  ? 'active flex items-center gap-x-1  font-semibold leading-6 text-white bg-blue-900'
                  : 'nav-border flex items-center gap-x-1  font-semibold leading-6 text-gray-900'
              }`}
            >
              Ship
              <ChevronDownIcon
                className="h-5 w-5 flex-none #011c2a"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 ">
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4  leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon
                          className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-auto">
                        <Link
                          to={item.to}
                          className="block font-semibold text-gray-900 "
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <Link
            to="/dashboard/aboutus"
            className={`link ${
              location.pathname === '/dashboard/aboutus'
                ? 'active'
                : ' nav-border'
            }`}
          >
            About us
          </Link>
          <Link
            to="/dashboard/shipment"
            className={`link ${
              location.pathname === '/dashboard/shipment'
                ? 'active'
                : ' nav-border'
            }`}
          >
            Track your shipment
          </Link>
          <Link
            to="/dashboard/contactus"
            className={`link ${
              location.pathname === '/dashboard/contactus'
                ? 'active'
                : ' nav-border'
            }`}
          >
            Contact us
          </Link>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {isLoggedIn ? (
            <Link
              to="/"
              onClick={handleLogout}
              className={`link ${
                location.pathname === '/' ? 'active' : ' nav-border'
              }`}
            >
              Logout <span aria-hidden="true">&rarr;</span>
            </Link>
          ) : (
            <Link
              to="/login"
              className={`link ${
                location.pathname === '/login' ? 'active' : ' nav-border'
              }`}
            >
              Log in <span aria-hidden="true">&rarr;</span>
            </Link>
          )}
        </div>
      </nav>
      <Dialog
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="#" style={{ marginLeft: '-6%' }}>
              <Image width={200} src="/brandlogo.svg" alt="logo" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Ship
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'rotate-180' : '',
                            'h-5 w-5 flex-none',
                          )}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...products].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.to}
                            className="block rounded-lg py-2 pl-6 pr-3  font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Link
                  to="/dashboard/aboutus"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  About Us
                </Link>
                <Link
                  to="/dashboard/shipment"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Track your shipment
                </Link>
                <Link
                  to="/dashboard/contactus"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Contact us
                </Link>
              </div>
              <div className="py-6">
                {isLoggedIn ? (
                  <Link
                    to="/"
                    onClick={handleLogout}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log out
                  </Link>
                ) : (
                  <Link
                    to="/login"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </Link>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Header;
